<template>
    <div>
        <v-carousel cycle hide-delimiters :show-arrows="false"  transition="fade-transition" class="carousel" height="100vh" >
            <v-carousel-item
            v-for="(item,i) in items"
            :key="i"
            :src="item.src"
           
            ></v-carousel-item>
        </v-carousel>

    </div>
        
</template>

<script>
    export default {
    data () {
      return {
        items: [
          {
            src: require('../assets/cb1.jpg')
          },
          {
            src: require('../assets/cb2.jpg'),
          },
          {
            src: require('../assets/cb3.jpg')
          },
          {
            src: require('../assets/cb4.jpg')
          },
          {
            src: require('../assets/cb7.jpg')
          },
          {
            src: require('../assets/cb8.jpg')
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>

.carousel {
    width: 100%;
}
</style>