<template>
    <div class="centerdiv" id="centerdivelement">
        <!-- Chessboard game-->
        <div class="leftcard">
            <v-card
                class="mx-auto my-12 chessboard-card"
                width="90%"
                height="93%"
            >
                <div class="userStats" v-show="opponentReady">
                    <div class="flex-row">
                        <v-img
                            class="image"
                            :src="this.userOpponent.image"
                        ></v-img>
                        <div style="margin-right: 5px">
                            {{ this.userOpponent.name }}
                        </div>
                        <div>( {{ this.userOpponent.elo }} )</div>
                    </div>
                    <div>
                        <div class="timer">
                            {{ this.minutes_opponent }} :
                            {{ this.seconds_opponent }}
                        </div>
                    </div>
                </div>
                <div class="chessboard">
                    <Chessboard
                        @gameFinished="onGameFinished"
                        @move="onMove"
                        @resign="onResign"
                        ref="cb"
                    ></Chessboard>
                </div>

                <div class="userStats" v-if="playing">
                    <div class="flex-row">
                        <v-img class="image" :src="this.user.image"></v-img>
                        <div style="margin-right: 5px">
                            {{ this.user.name }}
                        </div>
                        <div>( {{ this.user.elo }} )</div>
                    </div>
                    <div
                        style="
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                        "
                    >
                        <div class="timer">
                            {{ this.minutes_user }} : {{ this.seconds_user }}
                        </div>

                        <div style="margin-left: 10px">
                            <v-btn small @click="resign()">
                                <v-icon color="light-green accent-3"
                                    >mdi-flag</v-icon
                                >
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>

        <!-- Play options-->
        <div class="rightcard" v-if="rightCardDisplay">
            <v-card
                class="mx-auto my-12 rightcard-card"
                width="100%"
                height="93%"
                min-height="200px"
            >
                <div class="flex-column">
                    <div style="padding-top: 10px">Select Game Mode</div>
                    <v-btn
                        :disabled="playing"
                        style="margin-top: 10px"
                        class="button"
                        color="grey darken-3"
                        accent-3
                        dark
                        medium
                        elevation="5"
                        @click="preparePlay('mdi-lightning-bolt')"
                    >
                        Lightning
                        <v-icon color="yellow lighten-1"
                            >mdi-lightning-bolt</v-icon
                        >
                    </v-btn>

                    <v-btn
                        :disabled="playing"
                        style="margin-top: 10px"
                        class="button"
                        color="grey darken-3"
                        accent-3
                        dark
                        medium
                        elevation="5"
                        @click="preparePlay('mdi-speedometer')"
                    >
                        Speedy
                        <v-icon color="light-green accent-3"
                            >mdi-speedometer</v-icon
                        >
                    </v-btn>

                    <v-btn
                        :disabled="playing"
                        style="margin-top: 10px"
                        class="button"
                        color="grey darken-3"
                        accent-3
                        dark
                        medium
                        elevation="5"
                        @click="preparePlay('mdi-timer-sand')"
                    >
                        Generic
                        <v-icon color="brown darken-1">mdi-timer-sand</v-icon>
                    </v-btn>

                    <div style="padding-top: 20px">Random player</div>

                    <div class="flex-column">
                        <v-btn
                            :disabled="playing"
                            style="margin-top: 10px"
                            width="260px"
                            class="button"
                            color="green"
                            accent-3
                            dark
                            x-large
                            elevation="5"
                            @click="play()"
                        >
                            PLAY
                            <v-icon>{{ this.gameMode }}</v-icon>
                            <v-progress-circular
                                v-if="searching"
                                :size="20"
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </v-btn>

                        <v-btn
                            style="margin-top: 5px"
                            v-if="searching"
                            small
                            elevation="5"
                            @click="stopSearchingGame()"
                        >
                            <v-icon>mdi-close-thick</v-icon>
                        </v-btn>
                    </div>

                    <div>
                        <div style="margin-top: 40px">Custom Game</div>

                        <div>
                            <v-text-field
                                style="margin-top: 10px"
                                v-model="searchFriend"
                                placeholder="Search Friend"
                                outlined
                            ></v-text-field>
                        </div>

                        <div class="friends">
                            <v-card>
                                <v-list mx-auto class="friends-list">
                                    <template
                                        v-for="(item, z) in filteredFriends"
                                    >
                                        <v-list-item :key="z" class="listItem">
                                            <v-list-item-avatar>
                                                <v-img
                                                    :src="item.image"
                                                ></v-img>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-html="item.name"
                                                ></v-list-item-title>

                                                <v-list-item-title>
                                                    <v-btn
                                                        :disabled="playing"
                                                        width="80px"
                                                        height="35px"
                                                        color="grey darken-3"
                                                        accent-3
                                                        dark
                                                        small
                                                        elevation="5"
                                                        @click="
                                                            showInvitationGame(
                                                                item
                                                            )
                                                        "
                                                    >
                                                        <v-icon
                                                            color="blue darken-1"
                                                            >fa-chess-rook</v-icon
                                                        >
                                                    </v-btn>
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card>
                        </div>

                        <v-container class="px-0 flex-center" fluid>
                            <v-checkbox
                                v-model="privateGame"
                                label="Private Game"
                                hide-details
                            ></v-checkbox>
                        </v-container>

                        <div v-if="!sendInvitationGameHidder">
                            <v-btn
                                color="blue darken-3"
                                accent-3
                                dark
                                medium
                                elevation="5"
                                @click="sendInvitationGame()"
                            >
                                Game vs {{ this.inviatationUser.name }}
                            </v-btn>

                            <v-progress-circular
                                v-if="invitationSpinner"
                                :size="20"
                                indeterminate
                                color="primary"
                                style="margin-left: 5px"
                            ></v-progress-circular>

                            <v-btn
                                v-if="invitationSpinner"
                                small
                                style="margin-left: 5px"
                                @click="stopInvitationGame()"
                            >
                                <v-icon medium color="red">
                                    mdi-close-circle
                                </v-icon>
                            </v-btn>
                        </div>
                    </div>

                    <div>
                        <div style="margin-top: 40px">Invitation Game</div>

                        <div>
                            <v-text-field
                                style="margin-top: 10px"
                                v-model="searchInvitation"
                                placeholder="Search Invitation"
                                outlined
                            ></v-text-field>
                        </div>

                        <div class="friends">
                            <v-card style="margin-bottom: 10px">
                                <v-list mx-auto class="friends-list">
                                    <template
                                        v-for="(item, z) in invitationGames"
                                    >
                                        <v-list-item :key="z" class="listItem">
                                            <v-list-item-avatar>
                                                <v-img
                                                    :src="item.image"
                                                ></v-img>
                                            </v-list-item-avatar>

                                            <v-list-item-content>
                                                <v-list-item-title
                                                    v-html="item.name"
                                                ></v-list-item-title>

                                                <div class="flex-row">
                                                    <v-list-item-title>
                                                        <v-btn
                                                            small
                                                            @click="
                                                                acceptInvitationGame(
                                                                    item
                                                                )
                                                            "
                                                        >
                                                            <v-icon
                                                                medium
                                                                color="green"
                                                            >
                                                                mdi-check
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-list-item-title>

                                                    <v-list-item-title>
                                                        <v-btn
                                                            small
                                                            style="
                                                                margin-left: 20px;
                                                            "
                                                            @click="
                                                                deleteInvitationGame(
                                                                    item
                                                                )
                                                            "
                                                        >
                                                            <v-icon
                                                                medium
                                                                color="red"
                                                            >
                                                                mdi-close-circle
                                                            </v-icon>
                                                        </v-btn>
                                                    </v-list-item-title>
                                                </div>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-list>
                            </v-card>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>

        <!-- Winner loser dialog-->
        <v-col cols="auto">
            <v-dialog
                transition="dialog-top-transition"
                max-width="600"
                v-model="dialogWinLose"
            >
                <v-card>
                    <v-toolbar color="primary" dark>
                        Game Statistics: {{ this.winLoseSentence }}
                    </v-toolbar>
                    <div class="dialog">
                        <div class="flex-column">
                            <v-card class="flex-column" color="grey darken-3">
                                <v-img
                                    class="image"
                                    :src="this.userOpponent.image"
                                ></v-img>
                                <div style="margin-right: 5px">
                                    {{ this.userOpponent.name }}
                                </div>
                                <div class="elo">
                                    {{ this.userOpponent.elo }}
                                    <v-icon>{{ this.gameMode }}</v-icon>
                                </div>
                            </v-card>
                            <v-card
                                class="flex-column"
                                style="margin-top: 20px"
                                color="grey darken-3"
                            >
                                <v-img
                                    class="image"
                                    :src="this.user.image"
                                ></v-img>
                                <div style="margin-right: 5px">
                                    {{ this.user.name }}
                                </div>
                                <div class="elo">
                                    {{ this.user.elo }}
                                    <v-icon>{{ this.gameMode }}</v-icon>
                                </div>
                            </v-card>
                        </div>
                    </div>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeDialog">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </div>
</template>

<script>
import Chessboard from '../components/Chessboard.vue'
var chessGameFound = new Audio(require('../assets/match_found.mp3'))
var checkmate = new Audio(require('../assets/checkmate.mp3'))
export default {
    components: {
        Chessboard,
    },
    data() {
        return {
            userOpponent: {},
            gameMode: 'mdi-timer-sand',
            gameModeColor: 'brown darken-1',
            gameModes: [
                'mdi-lightning-bolt',
                'mdi-speedometer',
                'mdi-timer-sand',
            ],
            privateGame: false,
            friendsList: [],
            searchFriend: '',
            sendInvitationGameHidder: true,
            inviatationUser: {},
            roomName: '',
            playing: false,
            opponentReady: false,
            elo: [],
            opponentelo: [],
            gameModeElo: 3,
            playerElo: 3,
            minutes_user: 10,
            seconds_user: 0,
            minutes_opponent: 10,
            seconds_opponent: 0,
            timer_user: null,
            timer_opponent: null,
            dialogWinLose: false,
            winLoseSentence: '',
            turn: 0,
            searching: false,
            gameId: null,
            moves: 0,
            invitationSpinner: false,
            searchInvitation: '',
            invitationGames: [],
            gameId: 0,
            wasPlayingMoves: [],
            timerblack: null,
            timeBlack: 59,
            checkTimer: false,
            lastMoveTime: '',
            rightCardDisplay: true,
            userOpponent_aux: 1,
        }
    },

    sockets: {
        connect: function () {
            console.log('socket connected')
        },

        customEmit: function (data) {
            console.log(
                'this method was fired by the socket server. eg: io.emit("customEmit", data)'
            )
        },

        wasPlaying: function (data) {
            this.rightCardDisplay = false
            console.log('WAS PLAYING DATA', data)

            console.log(
                'This user was playing in room',
                data.roomId,
                'with game id',
                data.gameId
            )

            let timeWhenUserDisconnected = this.$moment(
                data.timeWhenUserDisconnected
            )
            let lastMovetime = this.$moment(data.game.lastMoveTime)

            let beforeMoveSeconds = timeWhenUserDisconnected.diff(
                lastMovetime,
                'seconds'
            )

            console.log('Before move seconds: ', beforeMoveSeconds)

            let minutesDisconnected = 0
            let secondsDisconnected = data.disconnectedTime - 1

            if (data.user1.id === this.user.id && data.game.turn === 1) {
                console.log('ENTRO EN 1')
                //Si soy el user 1 soy negras y si es mi turno
                this.minutes_user = data.game.user_minutes
                this.seconds_user = data.game.user_seconds
                this.minutes_opponent = data.game.opponent_minutes
                this.seconds_opponent = data.game.opponent_seconds

                secondsDisconnected = data.disconnectedTime
                secondsDisconnected = secondsDisconnected + beforeMoveSeconds
                console.log('entro es user disconencted')

                if (this.seconds_user - secondsDisconnected < 0) {
                    console.log(
                        'this.seconds user - seconds disconnected',
                        this.seconds_user - secondsDisconnected
                    )

                    let newSeconds = this.seconds_user - secondsDisconnected
                    newSeconds = 60 - newSeconds
                    this.minutes_user--
                    this.seconds_user = newSeconds

                    console.log('new seconds', this.secondsUser)
                } else {
                    this.seconds_user = this.seconds_user - secondsDisconnected
                }

                this.startTimerUser()
                this.stopTimerOpponent()
            } else if (data.user1.id === this.user.id && data.game.turn === 0) {
                console.log('ENTRO EN 2')
                //Si soy el user 1 soy negras y si es turno de las blancas
                this.minutes_user = data.game.user_minutes
                this.seconds_user = data.game.user_seconds
                this.minutes_opponent = data.game.opponent_minutes
                this.seconds_opponent = data.game.opponent_seconds
                this.startTimerOpponent()
                this.stopTimerUser()
            } else if (data.user1.id != this.user.id && data.game.turn === 1) {
                console.log('ENTRO EN 3')
                //Si no soy el user1 soy blancas y el turno es de el negro
                this.minutes_user = data.game.opponent_minutes
                this.seconds_user = data.game.opponent_seconds
                this.minutes_opponent = data.game.user_minutes
                this.seconds_opponent = data.game.user_seconds
                this.startTimerOpponent()
                this.stopTimerUser()
            } else if (data.user1.id != this.user.id && data.game.turn === 0) {
                console.log('ENTRO EN 4')
                //si no soy user1 soy blancas y el turno es blanco
                this.minutes_user = data.game.opponent_minutes
                this.seconds_user = data.game.opponent_seconds
                this.minutes_opponent = data.game.user_minutes
                this.seconds_opponent = data.game.user_seconds

                secondsDisconnected = data.disconnectedTime
                secondsDisconnected = secondsDisconnected + beforeMoveSeconds
                if (data.whoDisconnected === this.user.name) {
                    console.log('entro es user disconencted')

                    if (this.seconds_user - secondsDisconnected < 0) {
                        console.log(
                            'this.seconds user - seconds disconnected',
                            this.seconds_user - secondsDisconnected
                        )

                        let newSeconds = this.seconds_user - disconnectedTime
                        newSeconds = 60 - newSeconds
                        this.minutes_user--
                        this.seconds_user = newSeconds
                        console.log('new seconds', this.secondsUser)
                    } else {
                        this.seconds_user =
                            this.seconds_user - secondsDisconnected
                    }
                }

                this.startTimerUser()
                this.stopTimerOpponent()
            }

            console.log('USER: ', this.user)
            this.$api
                .get(`/score/${this.user.id}/elo`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.elo = response.data
                    console.log('response: ', response)
                    if (data.user1.gameMode === 1) {
                        this.user.elo = this.elo[0].score
                    } else if (data.user1.gameMode === 2) {
                        this.user.elo = this.elo[1].score
                    } else if (data.user1.gameMode === 3) {
                        this.user.elo = this.elo[2].score
                    }
                })
                .catch((error) => {
                    console.log(error)
                })

            this.roomName = data.roomId
            if (data.user1.id == this.user.id) {
                this.userOpponent = data.user2
            } else if (data.user2.id == this.user.id) {
                this.userOpponent = data.user1
            }

            this.opponentReady = true
            this.playing = true

            this.$api
                .get(`/games/${data.gameId}/moves`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.wasPlayingMoves = response.data
                    console.log('was playing moves', this.wasPlayingMoves)
                    //Poner el tiempo que habia
                    //Volver a empezar el juego para este usuario
                    let gameData = {
                        lastMoveFen: this.wasPlayingMoves.slice(-1),
                        roomId: data.roomId,
                        gameId: data.gameId,
                        user1: data.user1,
                        user2: data.user2,
                    }
                    this.$refs.cb.restartGame(gameData)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        createGame: function () {
            this.createGame()
        },

        getGameId: function (id) {
            this.$refs.cb.getGameId(id)
            this.gameId = id
        },

        playing: function (room) {
            this.rightCardDisplay = false

            console.log('window width: ', window.innerWidth)
            if (window.innerWidth <= 680) {
                document
                    .getElementById('centerdivelement')
                    .setAttribute('style', 'height: 700px !important;')
            }

            this.opponentReady = true

            console.log('refs', this.$refs.cb)
            console.log('ROOM', room)

            this.invitationSpinner = false
            console.log('minutes user', this.minutes_user)
            this.stopAndResetTimers()
            console.log('minutes user', this.minutes_user)

            this.searching = false
            this.playing = true
            console.log('data de socket playing', room, this.$socket.id)
            if (room.user1.id == this.user.id) {
                this.userOpponent = room.user2
                this.user['color'] = room.user1.color
            } else if (room.user2.id == this.user.id) {
                this.userOpponent = room.user1
                this.user['color'] = room.user2.color
            }

            console.log('user despues de poner el color en local', this.user)
            this.roomName = room.roomId
            console.log('Mi user oponent es:', this.userOpponent)

            if (this.roomName == this.$socket.id) {
                this.turn = 0
                this.startTimerUser()
            } else {
                this.turn = 1
                this.startTimerOpponent()
            }

            if (this.userOpponent_aux != 1)
                this.userOpponent.elo = this.userOpponent_aux

            this.$refs.cb.determineColor(room.roomId)
            console.log('despues de oponent ready')
            chessGameFound.play()
            console.log('despues de play')
        },

        move: function (move) {
            console.log('check timer de black:', this.checkTimer)
            console.log('move turn es: ', move.turn)
            console.log('this.user', this.user)
            if (
                move.turn === 1 &&
                this.checkTimer === false &&
                this.user.color === 'Black'
            ) {
                this.checkTimer = true
                this.timerblack = setInterval(() => {
                    this.timeBlack--
                    console.log('time black: ', this.timeBlack)

                    if (this.timeBlack === 0) {
                        //You lose becouse you didnt move
                        console.log('entro en user time es 0')
                        clearInterval(this.timerblack)
                        if (this.$socket.id != this.roomName) {
                            this.resign()
                        }

                        //this.$emit('resign', this.timeBlack)
                    }
                }, 1000)
            }

            console.log('ref chess', this.$refs.cb)
            this.$refs.cb.move(move)
            if (this.roomName == this.$socket.id && this.turn === 0) {
                console.log('entro en 1')
                //Soy el blanco i es mi turno
                this.stopTimerUser()
                this.startTimerOpponent()
                this.turn = 1
            } else if (this.roomName == this.$socket.id && this.turn === 1) {
                console.log('entro en 2')
                //Soy el blanco, turno del negro
                this.stopTimerOpponent()
                this.startTimerUser()
                this.turn = 0
            }

            if (this.roomName != this.$socket.id && this.turn === 0) {
                console.log('entro en 3')
                //Soy el negro, turno del blanco
                this.stopTimerUser()
                this.startTimerOpponent()
                this.turn = 1
            } else if (this.roomName != this.$socket.id && this.turn === 1) {
                console.log('entro en 4')
                //Soy el negro turno del negro
                this.startTimerUser()
                this.stopTimerOpponent()
                this.turn = 0
            }
        },

        gameEndedDisconnectedUser: function (user) {
            console.log('game ended disconnected user: ', user)
            this.stopAndResetTimers()
            this.winLoseSentence = 'Player disconnected'
            this.user.elo = this.user.elo + 9
            this.userOpponent.elo = this.userOpponent.elo - 9
            this.dialogWinLose = true
            let stats = {
                user_white: 1,
                user_black: 1,
                white_final_score: 1,
                black_final_score: 1,
                winner: 1,
                moves: 1,
                gamemode: this.gameModeElo,
                private: 1,
            }

            if (user.color === 'White') {
                stats.winner = 1
            } else {
                stats.winner = 0
            }

            checkmate.play()
            this.saveUpdateGame(stats)

            switch (this.gameModeElo) {
                case 1:
                    userElo = {
                        gamemode_id: 1,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 1,
                        score: this.userOpponent.elo,
                    }
                    break
                case 2:
                    userElo = {
                        gamemode_id: 2,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 2,
                        score: this.userOpponent.elo,
                    }
                    break
                case 3:
                    userElo = {
                        gamemode_id: 3,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 3,
                        score: this.userOpponent.elo,
                    }
                    break
            }
            this.updateUsersElo(userElo, opponentElo)
        },

        gameEnded: function (stats) {
            console.log('entro en game ended')
            console.log('stats', stats)
            this.stopAndResetTimers()
            console.log(
                'room name:',
                this.roomName,
                'socket id: ',
                this.$socket.id
            )
            if (stats.winner == 0) {
                this.winLoseSentence = 'White Won'
                if (this.roomName == this.$socket.id) {
                    this.user.elo = this.user.elo + 9
                    this.userOpponent.elo = this.userOpponent.elo - 9
                } else {
                    this.userOpponent.elo = this.userOpponent.elo + 9
                    this.user.elo = this.user.elo - 9
                }
            } else if (stats.winner == 1) {
                this.winLoseSentence = 'Black Won'
                if (this.roomName == this.$socket.id) {
                    this.user.elo = this.user.elo - 9
                    this.userOpponent.elo = this.userOpponent.elo + 9
                } else {
                    this.userOpponent.elo = this.userOpponent.elo - 9
                    this.user.elo = this.user.elo + 9
                }
            } else if (stats.winner == 2) {
                this.winLoseSentence = 'Draw'
            }

            this.dialogWinLose = true
            checkmate.play()
            this.saveUpdateGame(stats)

            let userElo = {}
            let opponentElo = {}
            switch (this.gameModeElo) {
                case 1:
                    userElo = {
                        gamemode_id: 1,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 1,
                        score: this.userOpponent.elo,
                    }
                    break
                case 2:
                    userElo = {
                        gamemode_id: 2,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 2,
                        score: this.userOpponent.elo,
                    }
                    break
                case 3:
                    userElo = {
                        gamemode_id: 3,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 3,
                        score: this.userOpponent.elo,
                    }
                    break
            }
            this.updateUsersElo(userElo, opponentElo)
        },

        resign: function (game) {
            this.stopAndResetTimers()
            let stats = {
                user_white: 1,
                user_black: 1,
                white_final_score: 1,
                black_final_score: 1,
                winner: game.winner,
                moves: 1,
                gamemode: this.gameModeElo,
                private: 0,
            }

            if (this.privateGame === false || this.privateGame === undefined) {
                stats.private = 0
            } else if (this.privateGame === true) {
                stats.private = 1
            }

            if (game.winner === 0) {
                this.winLoseSentence = 'White Won'
                if (this.roomName == this.$socket.id) {
                    this.user.elo = this.user.elo + 9
                    this.userOpponent.elo = this.userOpponent.elo - 9
                } else {
                    this.userOpponent.elo = this.userOpponent.elo + 9
                    this.user.elo = this.user.elo - 9
                }
            } else {
                this.winLoseSentence = 'Black Won'
                if (this.roomName == this.$socket.id) {
                    this.user.elo = this.user.elo - 9
                    this.userOpponent.elo = this.userOpponent.elo + 9
                } else {
                    this.userOpponent.elo = this.userOpponent.elo - 9
                    this.user.elo = this.user.elo + 9
                }
            }

            this.dialogWinLose = true
            checkmate.play()
            this.saveUpdateGame(stats)

            let userElo = {}
            let opponentElo = {}
            switch (this.gameModeElo) {
                case 1:
                    userElo = {
                        gamemode_id: 1,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 1,
                        score: this.userOpponent.elo,
                    }
                    break
                case 2:
                    userElo = {
                        gamemode_id: 2,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 2,
                        score: this.userOpponent.elo,
                    }
                    break
                case 3:
                    userElo = {
                        gamemode_id: 3,
                        score: this.user.elo,
                    }

                    opponentElo = {
                        gamemode_id: 3,
                        score: this.userOpponent.elo,
                    }
                    break
            }

            this.updateUsersElo(userElo, opponentElo)
        },

        invitation: function (data) {
            console.log('data from invitation', data)
            this.privateGame = data.private
            console.log('PRIVATE GAME: ', this.privateGame)
            if (data.connected == 1) {
                let inviatation = {
                    name: data.user.name,
                    image: data.user.image,
                    private: data.private,
                    elo: data.elo,
                }
                this.invitationGames.push(inviatation)
            }

            console.log('invitations', this.invitationGames)
        },

        stopInvitation: function (data) {
            for (let i = 0; i < this.invitationGames.length; i++) {
                if (this.invitationGames[i].name == data.name) {
                    this.invitationGames.splice(i, 1)
                }
            }
        },
    },

    methods: {
        updateUsersElo(userElo, opponentElo) {
            this.$api
                .put(`/score/${this.user.id}/elo`, userElo, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {})
                .catch((error) => {
                    console.log(error)
                })

            this.$api
                .put(`/score/${this.userOpponent.id}/elo`, opponentElo, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {})
                .catch((error) => {
                    console.log(error)
                })
        },

        acceptInvitationGame(item) {
            console.log('this elo: ', this.elo)
            if (this.gameModeElo === 3) {
                this.user.elo = this.elo[2].score
            } else if (this.gameModeElo === 2) {
                this.user.elo = this.elo[1].score
            } else if (this.gameModeElo === 3) {
                this.user.elo = this.elo[0].score
            }

            console.log('ITEM ITEM ITEM ITEM ITEM', item)

            console.log('this useroponent elo: ', this.userOpponent.elo)
            this.userOpponent_aux = item.elo

            console.log('this useroponent elo: ', this.userOpponent.elo)

            let data = {
                userSend: item.name,
                userAccept: this.user,
                private: item.private,
                elo: this.user.elo,
            }
            this.$socket.emit('acceptInvitation', data)
            const index = this.invitationGames.indexOf(item)
            if (index > -1) {
                this.invitationGames.splice(index, 1)
            }
        },

        deleteInvitationGame(item) {
            const index = this.invitationGames.indexOf(item)
            if (index > -1) {
                this.invitationGames.splice(index, 1)
            }
        },

        stopInvitationGame() {
            let data = {
                userToCancel: this.user.name,
                userToSend: this.inviatationUser.name,
            }
            this.invitationSpinner = false
            this.$socket.emit('stopInvitation', data)
        },

        sendInvitationGame() {
            if (this.gameModeElo === 3) {
                this.user.elo = this.elo[2].score
            } else if (this.gameModeElo === 2) {
                this.user.elo = this.elo[1].score
            } else if (this.gameModeElo === 3) {
                this.user.elo = this.elo[0].score
            }

            console.log('Sockets')
            console.log('private game:', this.privateGame)
            let data = {
                userChallengedName: this.inviatationUser.name,
                userSendChallenge: this.user.name,
                elo: this.user.elo,
            }

            if (this.privateGame === false || this.privateGame === undefined) {
                data.private = 0
            } else {
                data.private = 1
            }

            this.invitationSpinner = true

            console.log('data', data)
            this.$socket.emit('invitation', data)
        },

        onGameFinished(stats) {
            checkmate.play()
            let game = {
                stats: stats,
                roomName: this.roomName,
            }
            this.$socket.emit('gameEnded', game)
        },

        resign() {
            console.log('user resigned')
            checkmate.play()

            let winner = 3
            if (this.roomName == this.$socket.id) {
                winner = 1
            } else {
                winner = 0
            }

            let game = {
                roomName: this.roomName,
                winner: winner,
            }

            this.$socket.emit('resign', game)
        },

        onResign(time) {
            console.log('entro en on resigned')
            console.log('user resigned')
            checkmate.play()

            let winner = 3
            if (this.roomName == this.$socket.id) {
                winner = 1
            } else {
                winner = 0
            }

            let game = {
                roomName: this.roomName,
                winner: winner,
                user: this.user,
            }

            this.$socket.emit('resign', game)
        },

        createGame() {
            this.game = {}
            if (this.roomName == this.$socket.id) {
                this.game.user_white = this.user.id
                this.game.user_black = this.userOpponent.id
            } else {
                this.game.user_white = this.userOpponent.id
                this.game.user_black = this.user.id
            }
            this.game.gamemode = this.gameModeElo
            console.log('this privateGame', this.privateGame)
            if (this.privateGame === undefined || this.privateGame === false) {
                this.game.private = 0
            } else {
                this.game.private = 1
            }

            console.log('this game: ', this.game)
            this.$api
                .post(`/games`, this.game, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    console.log('response', response)
                    this.gameId = response.data.insertId
                    console.log('GAME INSERTED')
                    console.log('this game', this.game)
                    let game = {
                        roomName: this.roomName,
                        id: this.gameId,
                    }
                    this.$socket.emit('getGameId', game)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        saveUpdateGame(stats) {
            console.log('this user', this.user)
            console.log('this user opponent', this.userOpponent)
            delete stats.user_white
            delete stats.user_black
            if (this.roomName == this.$socket.id) {
                stats.white_final_score = this.user.elo
                stats.black_final_score = this.userOpponent.elo
            } else {
                stats.white_final_score = this.userOpponent.elo
                stats.black_final_score = this.user.elo
            }

            stats.gamemode = this.gameModeElo
            stats.moves = this.moves

            this.getGameMoves(this.gameId, stats)
            console.log('stats updated!!!!!!!!!!!!!!!!!!!!!', stats)
        },

        getGameMoves(id, stats) {
            this.$api
                .get(`/games/${id}/moves`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    stats.moves = response.data.length
                    this.updateGame(id, stats)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        updateGame(id, stats) {
            this.$api
                .put(`/games/${id}`, stats, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    console.log('Response', response)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        saveMove(game) {
            console.log('savew this game', this.game)
            const move = {
                game_id: this.gameId,
                fen: game.fen,
                name: game.name,
            }

            this.$api
                .post(`/games/moves`, move, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    console.log('move insertado en la base de datos')
                    this.moves++
                })
                .catch((error) => {
                    console.log('Error en el post', error)
                })
        },

        onMove(move) {
            clearInterval(this.timerblack)

            move.roomName = this.roomName
            console.log('user minutes:', this.seconds_user)
            move.user_minutes = this.minutes_user
            move.user_seconds = this.seconds_user
            move.opponent_minutes = this.minutes_opponent
            move.opponent_seconds = this.seconds_opponent
            move.lastMoveTime = new Date()
            console.log('move', move)
            console.log('llamada')
            this.$socket.emit('move', move)
            //this.saveMove(move)

            //Parar tiempo
        },

        play: function () {
            this.searching = true
            this.determineEloByGameMode()
            //Bloquear los demas botones
            this.playing = true
            this.user.gameMode = this.gameModeElo
            //this.user.elo = 1000
            console.log('this user', this.user)

            //Pedir al servidor una partida
            this.$socket.emit('play', this.user)

            //Poner el tiempo con el gamemodeId
            //this.startTimerUser()
        },

        stopSearchingGame() {
            this.searching = false
            this.playing = false
            this.$socket.emit('stopSearching', this.user)
        },

        stopAndResetTimers() {
            if (this.roomName == this.$socket.id && this.turn === 0) {
                this.stopTimerUser()
                this.stopTimerOpponent()
            } else if (this.roomName == this.$socket.id && this.turn === 1) {
                this.stopTimerUser()
                this.stopTimerOpponent()
            }

            if (this.roomName != this.$socket.id && this.turn === 0) {
                this.stopTimerUser()
                this.stopTimerOpponent()
            } else if (this.roomName != this.$socket.id && this.turn === 1) {
                this.stopTimerUser()
                this.stopTimerOpponent()
            }

            console.log('gmaemodeelo', this.gameModeElo)
            console.log('gamemode', this.gameMode)
            switch (this.gameMode) {
                case 1:
                    this.minutes_user = 1
                    this.minutes_opponent = 1
                    break
                case 2:
                    this.minutes_user = 3
                    this.minutes_opponent = 3
                    break
                case 3:
                    this.minutes_user = 10
                    this.minutes_opponent = 10
                    break
            }

            console.log('minutes user', this.minutes_user)
        },

        closeDialog() {
            this.dialogWinLose = false
            this.playing = false
            this.opponentReady = false
            this.rightCardDisplay = true
            this.$router.go()
        },

        startTimerUser() {
            console.log('userm inutes:', this.minutes_user)
            this.timer_user = setInterval(() => {
                if (this.seconds_user == 0) {
                    this.minutes_user--
                    this.seconds_user = 60
                }

                this.seconds_user--

                if (this.minutes_user == 0 && this.seconds_user == 0) {
                    //Abrir una interficie para saber que el jugador ha ganado o perdido
                    this.stopTimerUser()
                    this.stopTimerOpponent()
                    this.openLostOnTime('user')
                }
            }, 1000)
        },

        openLostOnTime(player) {
            switch (player) {
                case 'user':
                    //Saber si estoy jugando con blancas o con negras para determinar winLoseSentence
                    this.winLoseSentence = 'White lost on time'
                    this.winLoseSentence = 'Black lost on time'

                    this.dialogWinLose = true

                    const scoreUser = {
                        user_id: this.user.id,
                        gamemode_id: this.gameModeElo,
                        score: this.elo[this.gameModeElo] - 8,
                    }

                    const scoreOpponent = {
                        user_id: this.userOpponent.id,
                        gamemode_id: this.gameModeElo,
                        score: this.opponentelo[this.gameModeElo] + 8,
                    }

                    //si soy white, perder puntos y mostrar, si soy black ganar puntos y mostrar
                    //queries en la base de datos de la partida
                    break
                case 'opponent':
                    //Saber si estoy jugando con blancas o con negras para determinar winLoseSentence
                    this.winLoseSentence = 'White lost on time'
                    this.winLoseSentence = 'Black lost on time'
                    this.dialogWinLose = true
                    break
            }
        },

        stopTimerUser() {
            clearInterval(this.timer_user)
        },

        stopTimerOpponent() {
            clearInterval(this.timer_opponent)
        },

        startTimerOpponent() {
            this.timer_opponent = setInterval(() => {
                if (this.seconds_opponent == 0) {
                    this.minutes_opponent--
                    this.seconds_opponent = 60
                }

                this.seconds_opponent--

                if (this.minutes_opponent == 0 && this.seconds_opponent == 0) {
                    //Abrir una interficie para saber que el jugador ha ganado o perdido
                    this.stopTimerUser()
                    this.stopTimerOpponent()
                    this.openLostOnTime('opponent')
                }
            }, 1000)
        },

        determineEloByGameMode() {
            this.seconds_user = 0
            this.seconds_opponent = 0
            console.log('determine mode', this.gameModeElo)
            switch (this.gameModeElo) {
                case 1:
                    //this.gameModeElo = this.elo[0].score
                    this.user.elo = this.elo[0].score
                    this.minutes_user = 1
                    this.minutes_opponent = 1
                    break
                case 2:
                    //this.gameModeElo = this.elo[1].score
                    this.user.elo = this.elo[1].score
                    this.minutes_user = 3
                    this.minutes_opponent = 3
                    break
                case 3:
                    //this.gameModeElo = this.elo[2].score
                    this.user.elo = this.elo[2].score
                    this.minutes_user = 10
                    this.minutes_opponent = 10
                    break
            }
        },

        preparePlay(gameMode) {
            console.log('prepare play mode', this.gameModeElo)
            console.log('gamemode: ', gameMode)
            if (gameMode === 'mdi-lightning-bolt') {
                this.gameModeElo = 1
            } else if (gameMode === 'mdi-speedometer') {
                this.gameModeElo = 2
            } else if (gameMode === 'mdi-timer-sand') {
                this.gameModeElo = 3
            }

            this.gameMode = gameMode
            console.log('prepare play mode despues', this.gameModeElo)
        },

        getFriendsList() {
            this.$api
                .get(`/friends`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.friendsList = response.data
                    this.treatFriends(this.friendsList)
                    console.log('friendslist, ', this.friendsList)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        treatFriends: function (friends) {
            let imagepath =
                'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
            for (let i = 0; i < friends.length; i++) {
                friends[i].image = imagepath + friends[i].image
            }
        },

        showInvitationGame(user) {
            this.inviatationUser = user
            this.sendInvitationGameHidder = false
        },

        getUserELO() {
            this.$api
                .get(`/score/${this.user.id}/elo`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.elo = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },

    mounted() {
        this.getFriendsList()
        this.getUserELO()
        this.$socket.emit('iam', this.user)
    },

    computed: {
        user: function () {
            return this.$store.state.user
        },

        filteredFriends: function () {
            return this.friendsList.filter((friend) => {
                return friend.name
                    .toLowerCase()
                    .includes(this.searchFriend.toLowerCase())
            })
        },

        filteredInvitations: function () {
            return this.invitationSpinner.filter((invitation) => {
                return invitation.name
                    .toLowerCase()
                    .includes(this.searchInvitation.toLowerCase())
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.dialog {
    width: 100%;
    height: auto;
    display: flex !important;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    text-align: center;
    padding: 20px;

    .elo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 5px;
    }
    .flex-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;

        .image {
            margin-top: 5px;
            width: 70px !important;
            height: 70px !important;
            border-radius: 50%;
            margin-right: 5px;
        }
    }
}

.centerdiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 90vh !important;
    margin: 0 auto;

    .leftcard {
        height: 100%;
        width: 70%;
        display: flex;
        align-items: center;
        margin-top: 35px;

        .chessboard-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .userStats {
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                align-items: center;

                .flex-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .image {
                        width: 35px !important;
                        height: 35px !important;
                        border-radius: 50%;
                        margin-right: 5px;
                    }
                }
            }

            .chessboard {
                display: flex;
                justify-content: center;
                margin-top: 5px;
            }

            .timer {
                width: 55px;
                border: solid 2px white;
                font-family: sans-serif;
            }
        }
    }

    .rightcard {
        margin-left: 50px;
        height: 100%;
        width: 30%;

        .button {
            width: 157px;
            outline: none;
            position: relative;
            z-index: 1;
            border-radius: 20px;
        }

        .flex-column {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .flex-center {
            display: flex;
            justify-content: center;
        }

        .friends-list {
            height: 250px !important;
            overflow-y: scroll;
        }
    }

    .rightcard-card {
        overflow-y: scroll;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
    }
}

@media (max-height: 615px) {
    .chessboard {
        width: 400px !important;
        height: 400px !important;
    }

    .leftcard {
        width: 550px !important;
    }
}

@media (max-width: 1300px) {
    .centerdiv {
        display: flex;
        flex-direction: column;
        height: 100% !important;
    }

    .leftcard {
        width: 750px !important;
        height: auto !important;

        .chessboard-card {
            width: 100% !important;
        }
    }

    .rightcard {
        margin-left: 0px !important;
        height: auto !important;
        width: 400px !important;
    }
}

.chessboard {
    width: 700px;
    height: 700px;
}

@media (max-width: 700px) {
    .leftcard {
        height: 650px !important;
    }

    .rightcard {
        margin-left: 0px !important;
        height: auto !important;
        width: 400px !important;
    }

    .leftcard {
        width: 90% !important;

        .chessboard {
            width: 100%;
        }
    }

    .chessboard {
        height: 550px;
    }
}

@media (max-width: 600px) {
    .leftcard {
        height: 550px !important;
    }
}

@media (max-width: 500px) {
    .leftcard {
        height: 450px !important;
    }

    .rightcard {
        width: 380px !important;
    }
}

@media (max-width: 400px) {
    .rightcard {
        width: 300px !important;
    }
}
</style>