<template>
    <div class="centerdiv">
        <div class="leftcard">
            <v-card class="mx-auto my-12 chessboard">
                <div class="gameplayers" v-if="dataLoaded">
                    <div class="flex-row">
                        <v-img
                            class="image"
                            :src="this.white_user.image"
                        ></v-img>
                        <div>{{ this.white_user.name }}</div>
                    </div>
                    <div>vs</div>
                    <div class="flex-row" style="margin-left: 20px">
                        <v-img
                            class="image"
                            :src="this.black_user.image"
                        ></v-img>
                        <div>{{ this.black_user.name }}</div>
                    </div>
                </div>

                <div id="board"></div>
                <div class="academy-moves">
                    <v-btn icon @click="lastMove()" :disabled="lastMoveData">
                        <v-icon color="grey darken-2">fa-arrow-left</v-icon>
                    </v-btn>

                    <v-btn icon @click="nextMove()" :disabled="nextMoveData">
                        <v-icon color="grey darken-2">fa-arrow-right</v-icon>
                    </v-btn>

                    <v-btn icon @click="startPosition()">
                        <v-icon color="grey darken-2">mdi-reload</v-icon>
                    </v-btn>
                </div>
            </v-card>
        </div>

        <!-- Description and move list-->
        <div class="rightcard">
            <v-card class="mx-auto my-12 rightcard-card">
                <div class="moves">
                    <div class="moves-card-grid">
                        <div
                            style="
                                display: flex;
                                justify-content: space-evenly;
                                flex-direction: column;
                            "
                        >
                            <h4 style="margin: 5px">Game moves</h4>
                            <v-card class="move-list" elevation="10">
                                <v-card
                                    v-for="(move, i) in this.moves"
                                    :key="i"
                                    class="move-list-item"
                                    elevation="10"
                                >
                                    <div
                                        style="margin-left: 10px"
                                        @click="goToMove(move)"
                                    >
                                        {{ i + 1 }}. {{ move.name }}
                                    </div>
                                </v-card>
                            </v-card>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import ChessBoard from 'chessboardjs-vue'
var chessMoveSound = new Audio(require('../assets/chess_move.mp3'))
export default {
    data() {
        return {
            board: null,
            config: {
                orientation: 'white',
                position: 'start',
            },
            white_user: null,
            black_user: null,
            game: null,
            moves: [],
            move_index: -1,
            nextMoveData: false,
            lastMoveData: false,
            dataLoaded: false,
        }
    },

    methods: {
        goToMove(move) {
            this.board.position(move.fen)
        },

        startPosition() {
            let fen = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1'
            this.board.position(fen)
            this.move_index = 0
        },

        nextMove() {
            if (this.move_index < -1) {
                this.move_index = -1
            }

            this.move_index++

            if (this.move_index > this.moves.length - 1) {
                this.move_index--
            } else {
                this.board.position(this.moves[this.move_index].fen)
                chessMoveSound.play()
            }
        },

        lastMove() {
            this.move_index--
            if (this.move_index > -1) {
                this.board.position(this.moves[this.move_index].fen)
                chessMoveSound.play()
            } else {
                if (this.move_index === -1) {
                    chessMoveSound.play()
                    this.board.position('start')
                } else {
                    this.board.position('start')
                }
            }
        },

        revertColor() {},

        getGameStats(id) {
            this.$api
                .get(`/games/${id}`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.game = response.data
                    console.log('this game', this.game)
                    this.getPlayersFromGame(
                        this.game.user_white,
                        this.game.user_black
                    )
                    this.getMovesFromGame(id)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getPlayersFromGame(id_white, id_black) {
            this.$api
                .get(`/users/${id_white}/`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.white_user = response.data
                    let filepath =
                        'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
                    this.white_user.image = filepath + this.white_user.image
                    console.log('white user', this.white_user)
                })
                .catch((error) => {
                    console.log(error)
                })

            this.$api
                .get(`/users/${id_black}/`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.black_user = response.data
                    let filepath =
                        'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
                    this.black_user.image = filepath + this.black_user.image
                    this.dataLoaded = true
                    console.log('black user', this.black_user)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getMovesFromGame(id) {
            this.$api
                .get(`/games/${id}/moves`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.moves = response.data
                    console.log('moves', this.moves)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },

    mounted() {
        this.user = this.$store.state.user
        console.log('gameid: ', this.$route.params.id)
        this.getGameStats(this.$route.params.id)
        this.board = ChessBoard('board', this.config)
    },
}
</script>

<style lang="scss" scoped>
.centerdiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 90vh !important;
    margin: 0 auto;

    .leftcard {
        height: 100%;
        width: 80%;
        display: flex;
        justify-content: center;

        #board {
            height: 670px;
            width: 670px;
            margin: 10px;
        }

        .chessboard {
            height: 93%;
            width: 93%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }

    .rightcard {
        height: 100%;
        width: 50%;
    }
}

.moves {
    width: 95%;
    height: 50%;
}

.move-list {
    margin-top: 20px;
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-content: space-around;
    text-align: center;
    gap: 1em;

    .move-list-item {
        width: 70px;
        display: flex;
        flex-direction: row;
        height: 30px;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        padding: 10px 0;
    }
}

.moves-card {
    width: 100%;
    height: 100%;
}

.moves-card-grid {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 100% 0%;
}

.gameplayers {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.flex-row {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    .image {
        width: 35px !important;
        height: 35px !important;
        border-radius: 50%;
        margin-right: 5px;
    }
}

.rightcard-card {
    width: 90%;
    height: 93%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

@media (max-width: 1300px) and (max-height: 625px) {
    .centerdiv {
        height: 500px !important;
    }

    #board {
        width: 350px !important;
        height: 350px !important;
    }

    .leftcard {
        height: 500px !important;
    }
}

@media (max-width: 1100px) {
    .centerdiv {
        display: flex;
        flex-direction: column;
        height: 100% !important;
        justify-content: start;
    }

    .leftcard {
        width: 95% !important;
        height: 50% !important;
    }
    .rightcard {
        width: 95% !important;
        margin-left: 0px !important;
        height: 30% !important;
    }

    .moves {
        height: 90% !important;
    }
}

@media (max-width: 700px) {
    #board {
        width: 490px !important;
        height: 490px !important;
    }

    .leftcard {
        width: 95% !important;
        height: auto !important;
    }

    .rightcard {
        margin-left: 0px !important;
    }
}

@media (max-width: 630px) {
    #board {
        width: 390px !important;
        height: 390px !important;
    }
}

@media (max-width: 500px) {
    #board {
        width: 250px !important;
        height: 250px !important;
    }
}
</style>