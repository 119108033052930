<template>
    <div class="start">
        <div class="carousel">
            <Carousel-background />
        </div>

        <div class="content">
            <div class="typewritter">
                <TypeWritter />
            </div>

            <div class="loginRegister">
                <LoginRegister />
            </div>
        </div>
    </div>
</template>

<script>
import TypeWritter from '../components/TypeWritter.vue'
import CarouselBackground from '../components/CarouselBackground.vue'
import LoginRegister from '../components/LoginRegister.vue'

export default {
    components: {
        TypeWritter,
        CarouselBackground,
        LoginRegister,
    },

    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
.start {
    z-index: 1;
    height: 100%;
    overflow-y: auto;
}

.carousel {
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5; /* for demo purpose  */
}

.typewritter {
    z-index: 100;
}

.content {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.loginRegister {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
}

@media (max-width: 900px) {
    .content {
        grid-template-columns: 50% 50%;
    }
}

@media (max-width: 700px) {
    .content {
        display: flex;
        flex-direction: column;
    }

    .typewritter {
        height: 100px;
        margin-top: 50px;
    }
}
</style>