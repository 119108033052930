<template>
    <div>
        <div class="conversations">
            <v-card class="conversationscard">
                <div class="conversationspeople">
                    <div class="search">
                        <v-btn
                            medium
                            style="margin-bottom: 10px"
                            @click="searchUser"
                        >
                            <v-icon medium color="green"> mdi-magnify </v-icon>
                        </v-btn>

                        <v-text-field
                            v-model="name"
                            placeholder="Search by name..."
                            outlined
                        ></v-text-field>
                    </div>

                    <v-list three-line class="v-list">
                        <div v-for="(user, i) in users" :key="i">
                            <v-subheader
                                v-if="user.header"
                                v-text="user.header"
                            ></v-subheader>

                            <v-divider
                                v-else-if="user.divider"
                                :inset="user.inset"
                            ></v-divider>

                            <v-list-item
                                v-else
                                @click="loadUserConversations(user)"
                            >
                                <v-list-item-avatar>
                                    <v-img :src="user.image"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <div class="flex-row">
                                        <v-list-item-title
                                            v-html="user.name"
                                        ></v-list-item-title>
                                        <div>
                                            <v-card elevation="5">
                                                <v-btn
                                                    icon
                                                    @click="
                                                        goToProfile(user.name)
                                                    "
                                                >
                                                    <v-icon
                                                        color="light-green darken-2"
                                                        >fa-user-circle</v-icon
                                                    >
                                                </v-btn>
                                            </v-card>
                                        </div>
                                    </div>

                                    <v-list-item-subtitle
                                        v-html="user.lastMessage"
                                    ></v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list>
                </div>

                <div class="conversationflexbox">
                    <div class="conversationsmessages" id="messages">
                        <div v-for="(message, m) in messages" :key="m">
                            <div class="messages">
                                <v-card
                                    class="conversationmessagescard"
                                    elevation="10"
                                >
                                    <div class="contentdisplay">
                                        <div class="messagecontent">
                                            <div class="nameAndTime">
                                                <div
                                                    v-bind:class="
                                                        classObject(
                                                            message.name
                                                        )
                                                    "
                                                >
                                                    {{ message.name }}
                                                </div>
                                                <div
                                                    class="time"
                                                    style="text-align: right"
                                                >
                                                    {{ message.timestamp }}
                                                </div>
                                            </div>
                                            <div class="content">
                                                <div>
                                                    {{ message.content }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </v-card>
                            </div>
                            <br :key="m" />
                        </div>
                    </div>

                    <div class="newmessagebox">
                        <div class="newmessage">
                            <v-text-field
                                v-model="newmessage"
                                label="Type a message..."
                                outlined
                                dense
                            ></v-text-field>

                            <v-btn icon @click="sendMessageAndEmitToServer">
                                <v-icon>mdi-send</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>

        <!-- User does not exists -->
        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="UserNotFoundDialog" width="500">
                    <v-card>
                        <v-card-title class="headline cyan lighten-2">
                            This user was not found!
                        </v-card-title>
                        <br />

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn
                                color="primary"
                                text
                                @click="UserNotFoundDialog = false"
                            >
                                OK
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <!-- User exists -->
        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="writeMessageDialog" width="500">
                    <v-card>
                        <v-card-title class="headline cyan lighten-2">
                            Send a message!
                        </v-card-title>
                        <br />

                        <v-card-text>
                            <v-text-field
                                v-model="introduceMessage"
                                label="Message..."
                                required
                                outlined
                                dense
                            ></v-text-field>
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-btn
                                color="primary"
                                text
                                @click="writeMessageDialog = false"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                                color="primary"
                                text
                                @click="sendIntroducedMessage"
                            >
                                Send
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import socketStore from '../main'
export default {
    data() {
        return {
            timeParsed: '',
            userFromEmail: [],
            introduceMessage: '',
            searchEmail: '',
            introduceMessageDialog: null,
            UserNotFoundDialog: null,
            writeMessageDialog: null,
            name: null,
            userFromName: null,
            newmessage: '',
            messages: [
                {
                    content: 'Fes click a una conversacio',
                    timeStamp: '03-12, 13:00',
                    name: 'User1',
                },
                {
                    content: 'Per veure els missatges',
                    timeStamp: '03-12, 13:01',
                    name: 'User2',
                },
            ],

            users: [],
            user_id_to: 0,

            usersNames: [{ name: 'User1' }, { name: 'User2' }],

            greenClass: 'nameGreen',
            cyanClass: 'nameCyan',
            conversationOpened: false,
        }
    },

    sockets: {
        connect: function () {
            console.log('socket connected')
        },

        message: function (msg) {
            var date = new Date()
            var month = this.pad2(date.getMonth() + 1) //months (0-11)
            var day = this.pad2(date.getDate()) //day (1-31)
            var year = date.getFullYear()
            var h = this.addZero(date.getHours())
            var m = this.addZero(date.getMinutes())
            var s = this.addZero(date.getSeconds())
            var formattedDate = +month + '-' + day + ' ' + h + ':' + m

            let message = {
                content: msg.content,
                user_id_from: msg.user_id_from,
                user_id_to: msg.user_id_to,
                timestamp: formattedDate,
                name: msg.user_name_to,
            }
            console.log('Mensaje desde el servidor', message)
            if (this.conversationOpened === true) {
                this.messages.push(message)
            }

            let messageBox = document.getElementById('messages')
            setTimeout(() => {
                messageBox.scrollTop = messageBox.scrollHeight + 200
            }, 10)
        },
    },

    methods: {
        sendMessageAndEmitToServer(message) {
            let messageBody = {
                content: this.newmessage,
                user_id_from: this.user.id,
                user_id_to: this.user_id_to,
            }

            this.$api
                .post(`/messages`, messageBody, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.updateLocalList(messageBody)
                })
                .catch((error) => {
                    console.log(error)
                })

            messageBody.user_name_to = this.user.name
            this.$socket.emit('message', messageBody)
        },

        sendIntroducedMessage() {
            console.log('user shearched, ', this.userFromName)
            let messageBody = {
                content: this.introduceMessage,
                user_id_from: this.user.id,
                user_id_to: this.userFromName.id,
            }

            console.log('messagebody', messageBody)

            this.$api
                .post(`/messages`, messageBody, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.writeMessageDialog = false
                    this.introduceMessageDialog = true
                    this.getUserWhoIConversateWith()
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        updateLocalList(messageBody) {
            var date = new Date()
            var month = this.pad2(date.getMonth() + 1) //months (0-11)
            var day = this.pad2(date.getDate()) //day (1-31)
            var year = date.getFullYear()
            var h = this.addZero(date.getHours())
            var m = this.addZero(date.getMinutes())
            var s = this.addZero(date.getSeconds())
            var formattedDate = +month + '-' + day + ' ' + h + ':' + m

            let message = {
                content: messageBody.content,
                user_id_from: messageBody.user_id_from,
                user_id_to: messageBody.user_id_to,
                timestamp: formattedDate,
                name: this.user.name,
            }
            this.messages.push(message)
            this.newmessage = ''

            let messageBox = document.getElementById('messages')
            setTimeout(() => {
                messageBox.scrollTop = messageBox.scrollHeight + 200
            }, 10)
        },

        pad2(n) {
            return (n < 10 ? '0' : '') + n
        },

        addZero(i) {
            if (i < 10) {
                i = '0' + i
            }
            return i
        },

        classObject: function (username) {
            if (this.usersNames[0].name == username) {
                return this.greenClass
            } else {
                return this.cyanClass
            }
        },

        searchUser() {
            this.$api
                .get(`/users/${this.searchEmail}/search`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.userFromEmail = response.data
                    if (response.data.length == 1) {
                        this.writeMessageDialog = true
                    } else {
                        this.UserNotFoundDialog = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getUserWhoIConversateWith() {
            this.$api
                .get(`/messages/users`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.users = response.data
                    this.users.unshift({ header: 'Today' })
                    this.treatUsers(this.users)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        treatUsers: function (users) {
            let imagepath =
                'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
            for (let i = 0; i < users.length; i++) {
                users[i].image = imagepath + users[i].image
            }
        },

        loadUserConversations(user) {
            this.conversationOpened = true
            this.usersNames = []
            this.usersNames.push({ name: this.user.name })
            this.usersNames.push({ name: user.name })
            this.user_id_to = user.id
            this.user_name_to = user.name

            this.$api
                .get(`/messages/${user.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.messages = response.data
                    this.addNamesToMessages(this.messages)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        addNamesToMessages: function (messages) {
            messages.forEach(function (message) {
                message.name = ''
            })

            for (let i = 0; i < messages.length; i++) {
                if (messages[i].user_id_from === this.user.id) {
                    messages[i].name = this.usersNames[0].name
                } else {
                    messages[i].name = this.usersNames[1].name
                }
                let time = messages[i].timestamp
                this.timeParsed = new Date(time)
                let hourParsed = this.timeParsed.toString()
                messages[i].timestamp =
                    time.slice(5, 10) + ', ' + hourParsed.slice(16, 21)
            }

            let messageBox = document.getElementById('messages')
            setTimeout(() => {
                messageBox.scrollTop = messageBox.scrollHeight + 200
            }, 10)
        },

        goToProfile(name) {
            this.$router.push({ path: `profile/${name}`, params: { name } })
        },

        searchUser() {
            this.$api
                .get(`/users/search/${this.name}`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    console.log('response', response)
                    this.userFromName = response.data
                    this.writeMessageDialog = true
                })
                .catch((error) => {
                    console.log(error)
                    this.UserNotFoundDialog = true
                })
        },
    },

    mounted() {
        this.getUserWhoIConversateWith()
    },

    computed: {
        user: function () {
            return this.$store.state.user
        },
    },
}
</script>

<style lang="scss" scoped>
.main {
    .conversations {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .conversationscard {
            margin-top: 15px;
            margin-bottom: 20px;
            width: 90%;
            height: auto;
            display: flex;
            flex-direction: row;

            .conversationspeople {
                width: 300px;
                height: 70%;

                .v-list {
                    margin-top: 2px;
                    height: 65vh; /* or any height you want */
                    overflow-y: auto;
                }

                .search {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 80%;
                    align-items: center;
                    text-align: center;
                    margin-top: 20px;
                    margin-left: 20px;
                }

                .form-control {
                    color: white;
                }
            }

            .conversationflexbox {
                width: 70%;
                height: 70vh;

                .newmessagebox {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .newmessage {
                        width: 90%;
                        height: 80px;
                        display: flex;
                        flex-direction: row;
                        margin-top: 20px;
                    }
                }

                .conversationsmessages {
                    width: 100%;
                    overflow-y: auto;
                    height: 65vh;
                    margin-top: 30px;

                    .conversationmessagescard {
                        width: 90%;
                        justify-content: center;
                        display: flex;
                        border: 1px solid black;
                    }

                    .contentdisplay {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 100%;

                        .messagecontent {
                            display: flex;
                            flex-direction: column;
                            width: 100%;

                            .nameAndTime {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                width: 100%;

                                .nameGreen {
                                    height: 20px;
                                    width: 50%;
                                    margin-top: 10px;
                                    margin-left: 10px;
                                    color: rgb(76, 248, 168);
                                }

                                .nameCyan {
                                    height: 20px;
                                    width: 50%;
                                    margin-top: 10px;
                                    margin-left: 10px;
                                    color: cyan;
                                }

                                .time {
                                    height: 20px;
                                    width: 50%;
                                    text-align: right;
                                    margin-right: 10px;
                                    margin-top: 10px;
                                }
                            }

                            .content {
                                height: auto;
                                margin-left: 10px;
                                width: 90%;
                                margin-top: 15px;
                                margin-bottom: 10px;
                                font-size: 14px;
                            }
                        }
                    }

                    .messages {
                        display: flex;
                        justify-content: center;
                        width: 100%;
                        height: 80vh; /* or any height you want */
                        overflow-y: auto;
                        height: auto;
                    }
                }
            }
        }
    }

    .tluser {
        display: flex;
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }

    .flex-row {
        display: flex;
        flex-direction: row !important;
    }
}

@media (max-width: 400px) {
    .messages {
        width: 80%;
    }
}
</style>