<template>
    <v-card id="small">
        <div>
            <div style="padding-top: 20px; text-align: center">STATISTICS</div>
        </div>

        <div class="chartresponsive">
            <div class="chart">
                <div class="gamemode">
                    Lightning
                    <v-icon color="yellow lighten-1">mdi-lightning-bolt</v-icon>
                </div>

                <div class="chartstats">
                    <div class="flex" v-if="lightningChartLoaded">
                        <div class="elo">{{ this.elo[0].score }} ELO</div>
                        <div class="win">
                            {{ this.lightningStats[0].count }} wins
                        </div>
                        <div class="lose">
                            {{ this.lightningStats[1].count }} loses
                        </div>
                        <div class="draw">
                            {{ this.lightningStats[2].count }} draws
                        </div>
                    </div>

                    <doughnut-chart
                        class="chart1"
                        v-if="lightningChartLoaded"
                        :chart-data="lightningDataCollection"
                        :options="chartOptions"
                        :width="150"
                        :height="150"
                    >
                    </doughnut-chart>
                </div>
            </div>

            <div class="chart">
                <div class="gamemode">
                    Speedy
                    <v-icon color="light-green accent-3"
                        >mdi-speedometer</v-icon
                    >
                </div>

                <div class="chartstats">
                    <div class="flex" v-if="speedyChartLoaded">
                        <div class="elo">{{ this.elo[1].score }} ELO</div>
                        <div class="win">
                            {{ this.speedyStats[0].count }} wins
                        </div>
                        <div class="lose">
                            {{ this.speedyStats[1].count }} loses
                        </div>
                        <div class="draw">
                            {{ this.speedyStats[2].count }} draws
                        </div>
                    </div>

                    <doughnut-chart
                        class="chart3"
                        v-if="speedyChartLoaded"
                        :chart-data="speedyDataCollection"
                        :options="chartOptions"
                        :width="150"
                        :height="150"
                    >
                    </doughnut-chart>
                </div>
            </div>

            <div class="chart">
                <div class="gamemode">
                    Generic
                    <v-icon color="brown darken-1">mdi-timer-sand</v-icon>
                </div>

                <div class="chartstats" v-if="genericChartLoaded">
                    <div class="flex">
                        <div class="elo">{{ this.elo[2].score }} ELO</div>
                        <div class="win">
                            {{ this.genericStats[0].count }} wins
                        </div>
                        <div class="lose">
                            {{ this.genericStats[1].count }} loses
                        </div>
                        <div class="draw">
                            {{ this.genericStats[2].count }} draws
                        </div>
                    </div>

                    <doughnut-chart
                        class="chart10"
                        v-if="genericChartLoaded"
                        :chart-data="genericDataCollection"
                        :options="chartOptions"
                        :width="150"
                        :height="150"
                    >
                    </doughnut-chart>
                </div>
            </div>
        </div>
    </v-card>
</template>

<script>
import DoughnutChart from '../plugins/Doughnut.vue'
export default {
    components: { DoughnutChart },

    data() {
        return {
            lightningChartLoaded: null,
            speedyChartLoaded: null,
            genericChartLoaded: null,
            user: null,
            gameschart: null,
            datacollection: null,
            lightningStats: [],
            speedyStats: [],
            genericStats: [],
            lightningDataCollection: {},
            speedyDataCollection: {},
            genericDataCollection: {},
            elo: [],
            chartOptions: {
                elements: {
                    arc: {
                        borderWidth: 2, // <-- Set this to derired value
                        borderColor: '#252525',
                    },
                },
                maintainAspectRatio: false,
                responsive: true,
                legend: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: {
                    mode: 'index',
                    intersect: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                drawBorder: false,
                                display: false,
                            },
                            ticks: {
                                display: false,
                            },
                            drawOnChartArea: false,
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                            ticks: {
                                display: false,
                            },
                            drawOnChartArea: false,
                        },
                    ],
                },
            },
        }
    },

    methods: {
        getUserLightningStatistics() {
            this.$api
                .get(`users/${this.user.id}/games/lightning`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.lightningStats = response.data
                    this.treatDataCollection(this.lightningStats)
                    this.lightningDataCollection = {
                        labels: ['Wins', 'Loses', 'Draws'],
                        datasets: [
                            {
                                backgroundColor: ['green', 'red', '#393939'],
                                data: [
                                    this.lightningStats[1].count,
                                    this.lightningStats[0].count,
                                    this.lightningStats[2].count,
                                ],
                            },
                        ],
                    }
                    this.lightningChartLoaded = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getUserSpeedyStatistics() {
            this.$api
                .get(`users/${this.user.id}/games/speedy`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.speedyStats = response.data
                    this.treatDataCollection(this.speedyStats)
                    this.speedyDataCollection = {
                        labels: ['Wins', 'Loses', 'Draws'],
                        datasets: [
                            {
                                backgroundColor: ['green', 'red', '#393939'],
                                data: [
                                    this.speedyStats[1].count,
                                    this.speedyStats[0].count,
                                    this.speedyStats[2].count,
                                ],
                            },
                        ],
                    }
                    this.speedyChartLoaded = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getUserGenericStatistics() {
            this.$api
                .get(`users/${this.user.id}/games/generic`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.genericStats = response.data
                    console.log('generic stats: ', this.genericStats)
                    this.treatDataCollection(this.genericStats)
                    this.genericDataCollection = {
                        labels: ['Wins', 'Loses', 'Draws'],
                        datasets: [
                            {
                                backgroundColor: ['green', 'red', '#393939'],
                                data: [
                                    this.genericStats[1].count,
                                    this.genericStats[0].count,
                                    this.genericStats[2].count,
                                ],
                            },
                        ],
                    }
                    this.genericChartLoaded = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getUserELO() {
            this.$api
                .get(`/score/${this.user.id}/elo`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.elo = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        treatDataCollection(stats) {
            if (stats.length == 0) {
                stats[0] = { count: 0 }
                stats[1] = { count: 0 }
                stats[2] = { count: 0 }
            } else if (stats.length == 1) {
                stats[1] = { count: 0 }
                stats[2] = { count: 0 }
            } else if (stats.length == 2) {
                stats[2] = { count: 0 }
            }
        },
    },

    async mounted() {
        this.user = this.$store.state.user

        this.lightningChartLoaded = false
        this.speedyChartLoaded = false
        this.genericChartLoaded = false

        this.getUserELO()
        this.getUserLightningStatistics()
        this.getUserSpeedyStatistics()
        this.getUserGenericStatistics()
    },
}
</script>

<style lang="scss" scoped>
.small {
    max-width: 100%;
}

.chart {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    text-align: left;

    .gamemode {
        margin-left: 25px;
    }

    .flex {
        display: flex;
        flex-direction: column;

        .elo {
            color: rgb(110, 245, 255);
        }

        .win {
            color: greenyellow;
        }

        .lose {
            color: red;
        }

        .draw {
            color: grey;
        }
    }

    .chartstats {
        display: grid;
        grid-template-columns: 30% 70%;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        align-items: center;
    }
}

@media (max-width: 1000px) {
    .chartresponsive {
        display: flex;
        flex-direction: row;
    }
}

@media (max-width: 600px) {
    .chartresponsive {
        display: flex;
        flex-direction: column;
        width: 300px;
    }
}
</style>
            