<template>
    <v-card class="mx-auto my-12 friendsGrid" max-width="80%" height="90%">
        <div class="div-searchlist">
            <v-card class="searchlist" elevation="20">
                <div style="margin-top: 30px; margin-bottom: 10px">
                    Search User
                </div>

                <div class="search">
                    <v-btn
                        medium
                        style="margin-bottom: 10px"
                        @click="getUsersFromSearchList"
                    >
                        <v-icon medium color="green"> mdi-magnify </v-icon>
                    </v-btn>

                    <v-text-field
                        v-model="searchName"
                        placeholder="Search"
                        outlined
                    ></v-text-field>
                </div>

                <v-list mx-auto class="searchv-list">
                    <template v-for="(item, i) in searchList">
                        <v-list-item :key="i" class="listItem">
                            <v-list-item-avatar>
                                <v-img :src="item.image"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title
                                    v-html="item.name"
                                ></v-list-item-title>
                            </v-list-item-content>

                            <v-btn small @click="sendRequest(item)">
                                <v-icon medium color="green">
                                    mdi-account-check
                                </v-icon>
                            </v-btn>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card>
        </div>

        <div style="display: flex; justify-content: center; height: 90%">
            <v-card class="friendrequest" elevation="20">
                <div style="margin-top: 30px; margin-bottom: 10px">
                    Request's list
                </div>

                <div class="search">
                    <v-text-field
                        v-model="searchRequest"
                        placeholder="Search Request"
                        outlined
                    ></v-text-field>
                </div>

                <v-list mx-auto class="requestv-list">
                    <template v-for="(item, x) in filteredRequests">
                        <v-list-item :key="x">
                            <div
                                class="requestItem"
                                style="margin-bottom: 30px"
                            >
                                <div class="requestItemAvatarName">
                                    <div class="requestItemAvatar">
                                        <v-list-item-avatar
                                            style="
                                                margin-top: 20px;
                                                margin-left: 15px;
                                            "
                                        >
                                            <v-img :src="item.image"></v-img>
                                        </v-list-item-avatar>
                                    </div>

                                    <v-list-item-content>
                                        <v-list-item-title
                                            v-html="item.name"
                                        ></v-list-item-title>
                                    </v-list-item-content>
                                </div>

                                <div class="requestItemButtons">
                                    <v-btn
                                        small
                                        @click="addFriendFromRequest(item)"
                                    >
                                        <v-icon medium color="green">
                                            mdi-check
                                        </v-icon>
                                    </v-btn>

                                    <v-btn
                                        small
                                        style="margin-left: 20px"
                                        @click="deleteFriendRequest(item)"
                                    >
                                        <v-icon medium color="red">
                                            mdi-close-circle
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card>
        </div>

        <div style="display: flex; justify-content: center">
            <v-card class="friendslist" elevation="20">
                <div style="margin-top: 30px; margin-bottom: 10px">
                    Friend's list
                </div>

                <v-list mx-auto class="friendv-list">
                    <template v-for="(item, z) in friendsList">
                        <v-list-item :key="z" class="listItem">
                            <v-list-item-avatar>
                                <v-img :src="item.image"></v-img>
                            </v-list-item-avatar>

                            <v-list-item-content>
                                <v-list-item-title
                                    @click="goToProfile(item.name)"
                                    v-html="item.name"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                </v-list>
            </v-card>
        </div>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                    <v-card>
                        <v-card-title class="headline cyan lighten-2">
                            User Friendship sended!
                        </v-card-title>

                        <v-card-text>
                            <br />
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="white" text @click="dialog = false">
                                Okey
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="dialog2" width="500">
                    <v-card>
                        <v-card-text> No results for this search </v-card-text>

                        <v-card-actions>
                            <v-btn color="white" text @click="dialog2 = false">
                                Ok
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </v-card>
</template>

<script>
export default {
    data: () => ({
        searchName: '',
        dialog: false,
        dialog2: false,
        searchList: [],
        requestList: [],
        friendsList: [],
        searchRequest: '',
    }),

    methods: {
        goToProfile(name) {
            this.$router.push({ path: `/` })
            this.$router.push({ path: `profile/${name}`, params: { name } })
        },

        getFriendsList() {
            this.$api
                .get(`/friends`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.friendsList = response.data
                    console.log('friend after', this.friendsList)
                    this.treatEvents(this.friendsList)
                    console.log('friendslist, ', this.friendsList)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getRequestList() {
            this.$api
                .get(`/friends/requests`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.requestList = response.data
                    this.treatEvents(this.requestList)
                    console.log('requests, ', this.requestList)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getUsersFromSearchList() {
            let name = this.searchName
            this.$api
                .get(`/users/search?s=${name}`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.searchList = response.data
                    this.treatEvents(this.searchList)
                    console.log('searchlist, ', this.searchList)
                    if (this.searchList.length === 0) {
                        this.dialog2 = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        deleteFriendRequest(item) {
            this.$api
                .delete(`/friends/${item.id}`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    const index = this.requestList.indexOf(item)
                    if (index > -1) {
                        this.requestList.splice(index, 1)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        addFriendFromRequest(item) {
            this.$api
                .put(`/friends/${item.id}`, null, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    const index = this.requestList.indexOf(item)
                    if (index > -1) {
                        this.requestList.splice(index, 1)
                    }
                    this.friendsList.push(item)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        sendRequest(item) {
            this.$api
                .post(`/friends/${item.id}`, null, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    if ((response.status = 200)) {
                        this.dialog = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        treatEvents: function (users) {
            let imagepath = 'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
            for (let i = 0; i < users.length; i++) {
                users[i].image = imagepath + users[i].image
            }
        },
    },

    mounted() {
        this.getFriendsList()
        this.getRequestList()
    },

    computed: {
        user: function () {
            return this.$store.state.user
        },

        filteredRequests: function () {
            return this.requestList.filter((request) => {
                return request.name
                    .toLowerCase()
                    .includes(this.searchRequest.toLowerCase())
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.friendsGrid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: center;
    align-items: center;
    text-align: center;

    .searchfriend {
        display: flex;
        flex-direction: column;
        width: 90%;
        height: 78vh;
        align-items: center;

        .searchfriendcard {
            width: 90%;
            height: 70vh;

            .search {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .friendslist {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .friendv-list {
            width: 90%;
            margin-top: 5px;
            height: 70vh;
            overflow-y: auto;
        }
    }

    .searchlist {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .searchv-list {
            width: 90%;
            margin-top: 5px;
            height: 55vh;
            overflow-y: auto;
        }
    }

    .friendrequest {
        width: 90%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;

        .requestv-list {
            width: 90%;
            height: 100%;
            margin-top: 5px;
            height: 70vh;
            overflow-y: auto;

            .requestItem {
                border: 0.5px solid rgb(14, 13, 13);
                border-radius: 2%;
                width: 100%;
                height: 100%;
                display: grid;
                grid-template-rows: 70% 30%;
                justify-content: center;
                text-align: center;

                .requestItemAvatarName {
                    width: 100%;
                    height: 100%;
                    display: grid;
                    grid-template-rows: 50% 50%;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    .requestItemAvatar {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 900px) {
    .friendsGrid {
        display: grid;
        grid-template-columns: 70%;
        height: 1750px !important;

        .searchfriend {
            width: 90%;
            height: 200px;
            justify-content: center;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        .friendrequest {
            height: 700px;
            margin-bottom: 30px;
        }

        .friendslist {
            height: 52vh;
        }

        .searchlist {
            height: 52vh;
            margin-top: 30px;
        }
    }
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px rgb(24, 24, 24);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgb(111, 118, 126);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(24, 24, 24);
}

.div-searchlist {
    display: flex !important;
    justify-content: center !important;
}

@media (max-width: 475px) {
    .friendsGrid {
        max-width: 90% !important;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .div-searchlist {
        width: 300px !important;
        display: flex !important;
        justify-content: center !important;
    }

    .friendslist {
        width: 300px !important;
    }

    .friendrequest {
        margin-top: 30px !important;
        width: 300px !important;
    }
}
</style>