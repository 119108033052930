<template>
    <div>
        <div class="openings-defenses">
            <v-card color="openings-defenses-card">
                <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    color="basil"
                    grow
                >
                    <v-tab v-for="item in items" :key="item">
                        {{ item }}
                    </v-tab>
                </v-tabs>

                <!-- Openings -->
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div class="search">
                            <div class="search-container">
                                <v-text-field
                                    v-model="searchOpening"
                                    placeholder="Search Opening"
                                    outlined
                                ></v-text-field>
                            </div>
                        </div>

                        <v-card flat>
                            <v-container class="">
                                <v-row no-gutters style="margin-left: 15px">
                                    <v-col
                                        v-for="(opening, i) in filteredOpenings"
                                        :key="i"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                        xl="4"
                                    >
                                        <v-card
                                            class="singleCard"
                                            @click="openOpening(opening)"
                                        >
                                            <img
                                                :src="opening.image"
                                                alt=""
                                                style="
                                                    width: 100%;
                                                    max-height: 300px;
                                                "
                                            />
                                            <div class="cardData">
                                                <div>
                                                    {{ opening.name }}
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>

                    <!-- Defenses -->
                    <v-tab-item>
                        <div class="search">
                            <div class="search-container">
                                <v-text-field
                                    v-model="searchDefense"
                                    placeholder="Search Defense"
                                    outlined
                                ></v-text-field>
                            </div>
                        </div>

                        <v-card flat>
                            <v-container class="">
                                <v-row no-gutters style="margin-left: 15px">
                                    <v-col
                                        v-for="(defense, i) in filteredDefenses"
                                        :key="i"
                                        cols="12"
                                        sm="6"
                                        md="4"
                                        xl="4"
                                    >
                                        <v-card
                                            class="singleCard"
                                            @click="openDefense(defense)"
                                        >
                                            <img
                                                :src="defense.image"
                                                alt=""
                                                style="
                                                    width: 100%;
                                                    max-height: 300px;
                                                "
                                            />
                                            <div class="cardData">
                                                <div style="text-align: center">
                                                    {{ defense.name }}
                                                </div>
                                            </div>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </div>
    </div>
</template>

<script>
export default {
    data: function () {
        return {
            openings: [
                { name: 'london system', image: require('../assets/cb1.jpg') },
                { name: 'vienna system', image: require('../assets/cb1.jpg') },
                { name: 'italian game', image: require('../assets/cb1.jpg') },
                { name: 'ruy lopez', image: require('../assets/cb1.jpg') },
            ],
            defenses: [
                { name: 'Caro Kann', image: require('../assets/cb1.jpg') },
                { name: 'French Defense', image: require('../assets/cb1.jpg') },
                {
                    name: 'Sicilian Defense',
                    image: require('../assets/cb1.jpg'),
                },
                {
                    name: 'Scandinavian Defense',
                    image: require('../assets/cb1.jpg'),
                },
            ],
            tab: null,
            items: ['Openings', 'Defenses'],
            searchOpening: '',
            searchDefense: '',
        }
    },

    methods: {
        getOpenings() {
            this.$api
                .get(`/academy/openings`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    this.openings = response.data
                    this.treatData(this.openings)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getDefenses() {
            this.$api
                .get(`/academy/defenses`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    this.defenses = response.data
                    this.treatData(this.defenses)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        treatData(data) {
            let imagepath =
                'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
            for (let i = 0; i < data.length; i++) {
                data[i].image = imagepath + data[i].image
            }
        },

        openOpening(openingDefense) {
            this.$store.commit('saveOpeningDefense', openingDefense)
            var openingDefense2 = this.$store.state.openingDefense
            console.log('openingDefense in store', openingDefense2)
            this.$router.push({
                name: 'SingleOpeningDefense', //use name for router push
            })
        },

        openDefense(openingDefense) {
            this.$store.commit('saveOpeningDefense', openingDefense)
            this.$router.push({
                name: 'SingleOpeningDefense', //use name for router push
            })
        },
    },

    mounted() {
        this.getOpenings()
        this.getDefenses()
    },

    computed: {
        token: function () {
            return this.$store.state.user.token
        },

        filteredOpenings: function () {
            return this.openings.filter((opening) => {
                return opening.name
                    .toLowerCase()
                    .includes(this.searchOpening.toLowerCase())
            })
        },

        filteredDefenses: function () {
            return this.defenses.filter((defense) => {
                return defense.name
                    .toLowerCase()
                    .includes(this.searchDefense.toLowerCase())
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.basil--text {
    color: #000000 !important;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .openings-defenses {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;

        .search {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            margin-top: 20px;

            .search-container {
                width: 30%;
                text-align: center;
            }
        }

        .openings-defenses-card {
            margin-top: 10px;
            margin-bottom: 20px;
            width: 90%;

            .singleCard {
                border-radius: 10px;
                border: 1.5px solid #000000;
                max-height: 500px;
                margin: 30px 30px 20px 0px;

                .cardData {
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    height: 60px;
                }

                .buttons {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                }
            }
        }
    }

    .tluser {
        display: flex;
        width: 90%;
        flex-direction: column;
        justify-content: center;
    }
}
</style>