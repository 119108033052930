<template>
    <div v-if="status == 0" class="parentlogin">
        <v-card class="logincard">
            <v-form class="loginform">
                <div class="loginformcontent">
                    <div class="title">
                        <h1 class="title">Sign In</h1>
                    </div>
                    <v-form>
                        <v-container>
                            <v-col>
                                <v-row class="">
                                    <v-text-field
                                        label="Email"
                                        v-model="email"
                                        outlined
                                        dense
                                        color="#35e47e"
                                    ></v-text-field>
                                </v-row>

                                <v-row>
                                    <v-text-field
                                        label="Password"
                                        v-model="password"
                                        outlined
                                        dense
                                        type="password"
                                        color="#35e47e"
                                    ></v-text-field>
                                </v-row>
                            </v-col>
                        </v-container>
                    </v-form>

                    <v-row>
                        <v-btn
                            class="loginbtn"
                            outlined
                            elevation="10"
                            block
                            @click="login"
                        >
                            LOG IN
                        </v-btn>
                    </v-row>

                    <div class="signup">
                        <p>Don't you have an account?</p>
                        <v-btn
                            class="loginbtn"
                            outlined
                            elevation="10"
                            @click="goToregister"
                        >
                            Sign up
                        </v-btn>
                    </div>
                </div>
            </v-form>

            <div class="dialog" style="display: none">
                <div class="text-center">
                    <v-dialog v-model="dialog" width="500">
                        <v-card>
                            <v-card-title class="headline red lighten-2">
                                Error!
                            </v-card-title>
                            <v-card-text>
                                <br />
                                Credentials wrong!
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="white"
                                    text
                                    @click="dialog = false"
                                >
                                    Okey
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </div>
            </div>
        </v-card>
    </div>

    <div v-else class="parentregister">
        <v-card class="registercard">
            <v-form class="registerform">
                <div class="registerformcontent">
                    <div class="title">
                        <h1 class="title">Register</h1>
                    </div>
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <v-form @submit.prevent="submit">
                            <validation-provider name="image" rules="required">
                                <v-row class="labelrow">
                                    <v-file-input
                                        v-model="image"
                                        label="Profile Image"
                                        outlined
                                        dense
                                        color="#35e47e"
                                        @change="onFileSelected($event)"
                                        required
                                        type="file"
                                        prepend-icon=""
                                    ></v-file-input>
                                </v-row>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="Name"
                                rules="required|max:20"
                            >
                                <v-row class="labelrow">
                                    <v-text-field
                                        v-model="name"
                                        :counter="20"
                                        :error-messages="errors"
                                        label="Name"
                                        outlined
                                        dense
                                        color="#35e47e"
                                        required
                                    ></v-text-field>
                                </v-row>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="Email"
                                rules="required|email"
                            >
                                <v-row class="labelrow">
                                    <v-text-field
                                        v-model="email"
                                        :error-messages="errors"
                                        label="E-mail"
                                        outlined
                                        dense
                                        color="#35e47e"
                                        required
                                    ></v-text-field>
                                </v-row>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="password"
                                rules="required"
                            >
                                <v-row>
                                    <v-text-field
                                        v-model="password"
                                        :error-messages="errors"
                                        label="Password"
                                        :rules="[rules.passwordRules]"
                                        outlined
                                        dense
                                        color="#35e47e"
                                        type="password"
                                        required
                                    ></v-text-field>
                                </v-row>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="password"
                                rules="required"
                            >
                                <v-row>
                                    <v-text-field
                                        v-model="confirmpassword"
                                        :error-messages="errors"
                                        label="Confirm Password"
                                        :rules="[
                                            rules.confirmPasswordRules,
                                            passwordConfirmationRule,
                                        ]"
                                        outlined
                                        dense
                                        color="#35e47e"
                                        type="password"
                                        required
                                    ></v-text-field>
                                </v-row>
                            </validation-provider>
                        </v-form>

                        <div style="height: 30px"></div>

                        <v-row>
                            <v-btn
                                class="loginbtn"
                                block
                                outlined
                                elevation="10"
                                type="submit"
                                :disabled="invalid"
                                @click="register"
                            >
                                LOG IN
                            </v-btn>
                        </v-row>

                        <v-row>
                            <v-btn
                                class="loginbtn"
                                block
                                outlined
                                elevation="10"
                                style="margin-top: 10px"
                                @click="goToLogin"
                            >
                                Sign in
                            </v-btn>
                        </v-row>
                    </validation-observer>
                </div>
            </v-form>
        </v-card>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="dialog2" width="500">
                    <v-card>
                        <v-card-title class="headline cyan lighten-2">
                            Register done!
                        </v-card-title>

                        <v-card-text>
                            <br />
                            Congratulations! You registration to PChess was
                            sucessfully!
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="goToLogin">
                                Ok
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="dialog3" width="500">
                    <v-card>
                        <v-card-title class="headline red lighten-2">
                            Error!
                        </v-card-title>

                        <v-card-text>
                            <br />
                            Please fill all the parameters correctly
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="white" text @click="dialog3 = false">
                                Okey
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="nameTaken" width="500">
                    <v-card>
                        <v-card-title class="headline red lighten-2">
                            Error!
                        </v-card-title>

                        <v-card-text>
                            <br />
                            This name is taken! please user another name
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="white"
                                text
                                @click="nameTaken = false"
                            >
                                Okey
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="emailTaken" width="500">
                    <v-card>
                        <v-card-title class="headline red lighten-2">
                            Error!
                        </v-card-title>

                        <v-card-text>
                            <br />
                            This email is taken! please user another name
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="white"
                                text
                                @click="emailTaken = false"
                            >
                                Okey
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, max } from 'vee-validate/dist/rules'
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: 'Maximum {length} characters',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            status: 0,
            user: null,
            token: '',
            userIdAuthenticated: false,
            name: '',
            email: '',
            password: '',
            image: File,
            confirmpassword: '',
            image: null,
            dialog: false,
            dialog2: false,
            dialog3: false,
            nameTaken: false,
            emailTaken: false,
            rules: {
                passwordRules: (v) => !!v || 'Password is required',
                confirmPasswordRules: (v) => !!v || 'Password is required',
            },
        }
    },

    methods: {
        goToLogin: function () {
            this.status = !this.status
            this.clear()
        },

        goToregister: function () {
            this.status = !this.status
        },

        getUserInformationFromToken: function (token) {
            this.token = token
            var base64Url = token.split('.')[1]
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return (
                            '%' +
                            ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                        )
                    })
                    .join('')
            )

            return JSON.parse(jsonPayload)
        },

        clear() {
            this.image = null
            this.name = ''
            this.email = ''
            this.password = ''
            this.confirmpassword = ''
            this.$refs.observer.reset()
        },

        login: function () {
            console.log('email: ', this.email)
            console.log('password: ', this.password)

            this.$api
                .post('/login', {
                    email: this.email,
                    password: this.password,
                })
                .then((response) => {
                    this.user = this.getUserInformationFromToken(
                        response.data.accessToken
                    )
                    this.user.token = this.token
                    this.userIsAuthenticated = true

                    let imagepath =
                        'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
                    this.user.image = imagepath + this.user.image

                    this.user['gameId'] = 0
                    console.log('this.user', this.user)

                    //Guarda el usuario en la store
                    this.$store.commit('saveUser', this.user)

                    //Recoje el usuario de la store
                    this.$store.commit('setUserIsAuthenticated')

                    var user2 = this.$store.state.user
                    console.log('user in store', user2)

                    this.$router.push('/home')
                })
                .catch((error) => {
                    console.log('Error here', error)
                    this.dialog = true
                })
        },

        register: function () {
            this.$api
                .get(`/users/search?s=${this.name}`)
                .then((response) => {
                    console.log('response', response)
                    if (response.data[0].name === this.name) {
                        this.nameTaken = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                    console.log('email: ', this.email)
                    this.$api
                        .get(`/users/searchbyEmail/${this.email}`)
                        .then((response) => {
                            console.log('response', response)
                            if (response.data.email === this.email) {
                                this.emailTaken = true
                            }
                        })
                        .catch((error) => {
                            console.log(error)
                            this.$refs.observer.validate()
                            let formData = new FormData()

                            formData.append('image', this.image)
                            formData.append('name', this.name)
                            formData.append('email', this.email)
                            formData.append('password', this.password)

                            this.$api
                                .post('/users', formData)
                                .then((response) => {
                                    this.dialog2 = true
                                    console.log(response.status)
                                })
                                .catch((error) => {
                                    console.log(error)
                                    this.dialog3 = true
                                })
                        })
                })
        },

        onFileSelected(event) {
            this.image = event
            console.log(event)
            console.log('image', this.image)
        },

        checkPassword(invalid) {
            // correct: false
            if (true == invalid) {
                this.validPassword = false
            } else {
                this.validPassword = true
            }
        },
    },

    mounted() {},

    computed: {
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmpassword || 'Password must match'
        },
    },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Do+Hyeon&display=swap');

.parentlogin {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .logincard {
        background-color: transparent;
        border: 0.5px solid white;
        width: 300px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20%;

        .loginform {
            width: 100%;
            height: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: transparent;

            .loginformcontent {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: auto;
                height: auto;

                .title {
                    font-size: 40px !important;
                    font-family: 'Architects Daughter' !important;
                    margin-bottom: 20px;
                }

                .loginbtn {
                    width: 200px;
                }

                .signup {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 30px;
                }
            }
        }
    }
}

.parentregister {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .registercard {
        background-color: transparent;
        border: 0.5px solid white;
        width: 300px;
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20%;

        .registerform {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            background-color: transparent;

            .registerformcontent {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: auto;
                height: auto;

                .title {
                    font-size: 40px !important;
                    font-family: 'Architects Daughter' !important;
                    margin-bottom: 20px;
                }
            }
        }
    }

    .parent {
        width: 100%;
        height: 100%;
    }
}

@media (max-height: 800px) {
    .parentregister .registercard {
        height: 500px !important;
    }

    .parentlogin .logincard {
        height: 400px;
    }
}
</style>