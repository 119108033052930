<template>
    <div style="height: 100%; width: 100%">
        <div id="board" style="width: 100%"></div>
    </div>
</template>

<script>
import ChessBoard from 'chessboardjs-vue'
import Chess from 'chess.js'
var chessMoveSound = new Audio(require('../assets/chess_move.mp3'))
export default {
    data() {
        return {
            board: null,
            game: new Chess(),
            $status: $('#status'),
            $fen: $('#fen'),
            $pgn: $('#pgn'),
            whiteSquareGrey: '#a9a9a9',
            blackSquareGrey: '#696969',
            roomName: '',
            configBlack: {
                draggable: true,
                orientation: 'black',
                position: 'start',
                onDragStart: this.onDragStartBlack,
                onDrop: this.onDropBlack,
                onSnapEnd: this.onSnapEnd,
                onMouseoutSquare: this.onMouseoutSquare,
                onMouseoverSquare: this.onMouseoverSquareBlack,
                onSnapEnd: this.onSnapEnd,
            },
            config: {
                draggable: false,
                position: 'start',
                onDragStart: this.onDragStartWhite,
                onDrop: this.onDropWhite,
                onSnapEnd: this.onSnapEnd,
                onMouseoutSquare: this.onMouseoutSquare,
                onMouseoverSquare: this.onMouseoverSquareWhite,
                onSnapEnd: this.onSnapEnd,
            },
            gameId: null,
            timer: 0,
        }
    },

    sockets: {
        /*
        connect: function () {
            console.log('socket connected')
        },
        customEmit: function (data) {
            console.log(
                'this method was fired by the socket server. eg: io.emit("customEmit", data)'
            )
        },

        message: function (data) {
            console.log('Mensaje desde el servidor', data)
        },

        getRoom: function (roomName) {
            this.roomName = roomName
            console.log('Room name', this.roomName)
            if (this.roomName != this.$socket.id) {
                console.log('Second player joining room plays as black')
                this.board = ChessBoard('board', this.configBlack)
            }
        },
        */
    },

    methods: {
        restartGame: function (gameData) {
            this.gameId = gameData.gameId
            this.roomName = gameData.roomId
            console.log('ENTRO EN RESTARTGAME')
            console.log('GAMEDATA', gameData)
            console.log(
                'ROOM ID',
                gameData.roomId,
                'SOCKET ID',
                this.$socket.id
            )
            //color

            if (gameData.user1.id === this.user.id) {
                //Black
                this.game = Chess()
                this.game.load(gameData.lastMoveFen[0].fen)
                this.configBlack.position = gameData.lastMoveFen[0].fen
                this.board = ChessBoard('board', this.configBlack)
            } else {
                //White
                this.game = Chess()
                this.game.load(gameData.lastMoveFen[0].fen)
                this.config.position = gameData.lastMoveFen[0].fen
                this.config.draggable = true
                this.board = ChessBoard('board', this.config)
            }
        },

        determineColor: function (roomName) {
            //this.roomName = roomName
            console.log('Room name', roomName)
            console.log('sokcet id', this.$socket.id)
            let time = 60

            if (roomName != this.$socket.id) {
                console.log('Second player joining room plays as black')
                this.game = Chess()
                this.board = ChessBoard('board', this.configBlack)
                console.log('this board', this.board)
            } else {
                this.timer = setInterval(() => {
                    time--
                    console.log('time: ', time)

                    if (time === 0) {
                        console.log('entro en time es 0')
                        //You lose becouse you didnt move
                        clearInterval(this.timer)
                        this.$emit('resign', time)
                    }
                }, 1000)
                //this.game = Chess()
                //this.board = ChessBoard('board', this.config)
            }

            this.config.draggable = true
        },

        getGameId: function (id) {
            console.log('me envian el game id')
            this.gameId = id
        },

        move: function (move) {
            console.log('mode sended from server from play', move)
            this.game.move(move.move)
            this.board.position(this.game.fen())
        },

        clickButton: function () {
            // $socket is socket.io-client instance
            this.$socket.emit('message', 'Hola desde el cliete')
        },

        removeGreySquares: function () {
            $('#board .square-55d63').css('background', '')
        },

        greySquare: function (square) {
            var $square = $('#board .square-' + square)

            var background = this.whiteSquareGrey
            if ($square.hasClass('black-3c85d')) {
                background = this.blackSquareGrey
            }

            $square.css('background', background)
        },

        onDragStartWhite: function (source, piece, position, orientation) {
            // do not pick up pieces if the game is over
            if (this.game.game_over()) return false

            // only pick up pieces for the side to move
            if (
                (this.game.turn() === 'w' && piece.search(/^b/) !== -1) ||
                (this.game.turn() === 'b' &&
                    piece.search(/^w/) &&
                    piece.search(/^b/) !== -1)
            ) {
                return false
            }
        },

        onDragStartBlack: function (source, piece, position, orientation) {
            // do not pick up pieces if the game is over
            if (this.game.game_over()) return false

            // only pick up pieces for the side to move
            if (
                (this.game.turn() === 'w' &&
                    piece.search(/^b/) !== -1 &&
                    piece.search(/^w/) !== -1) ||
                (this.game.turn() === 'b' && piece.search(/^w/) !== -1)
            ) {
                return false
            }
        },

        onDropWhite: function (source, target) {
            if (this.game.turn() === 'w') {
                this.removeGreySquares()

                // see if the move is legal
                var gameMove = this.game.move({
                    from: source,
                    to: target,
                    promotion: 'q', // NOTE: always promote to a queen for example simplicity
                })

                // illegal move or sended to the server
                console.log('move', move)
                if (gameMove === null) return 'snapback'
                else
                    var move = {
                        roomName: this.roomName,
                        move: gameMove,
                        turn: 1,
                        fen: this.game.fen(),
                    }

                //Emit the movement to the server
                console.log('move', move)
                clearInterval(this.timer)
                this.$emit('move', move)

                //Save the movement to the database
                this.saveMove(move)
                this.updateStatus()
                chessMoveSound.play()
            }
        },

        saveMove(game) {
            console.log('this game', this.gameId)
            const move = {
                game_id: this.gameId,
                fen: this.game.fen(),
                name: game.move.to,
            }

            console.log('move antes de insertar', move)
            this.$api
                .post(`/games/move`, move, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    console.log('move insertado en la base de datos')
                })
                .catch((error) => {
                    console.log('Error en el post', error)
                })
        },

        onDropBlack: function (source, target) {
            console.log('entro')
            if (this.game.turn() === 'b') {
                this.removeGreySquares()

                // see if the move is legal
                var gameMove = this.game.move({
                    from: source,
                    to: target,
                    promotion: 'q', // NOTE: always promote to a queen for example simplicity
                })

                // illegal move or sended to the server
                if (gameMove === null) return 'snapback'
                else
                    var move = {
                        roomName: this.roomName,
                        move: gameMove,
                        turn: 0,
                        fen: this.game.fen(),
                    }

                //Emit the movement to the server
                console.log('move', move)
                this.$emit('move', move)
                console.log('Interval clear')

                this.saveMove(move)
                this.updateStatus()
                chessMoveSound.play()

                //Save the movement to the database
            }
        },

        onMouseoverSquareWhite: function (square, piece) {
            // get list of possible moves for this square
            var moves = this.game.moves({
                square: square,
                verbose: true,
            })

            // exit if there are no moves available for this square
            if (moves.length === 0) return

            // highlight the square they moused over
            if (this.game.turn() === 'w') {
                this.greySquare(square)

                // highlight the possible squares for this piece
                for (var i = 0; i < moves.length; i++) {
                    this.greySquare(moves[i].to)
                }
            }
        },

        onMouseoverSquareBlack: function (square, piece) {
            // get list of possible moves for this square
            var moves = this.game.moves({
                square: square,
                verbose: true,
            })

            // exit if there are no moves available for this square
            if (moves.length === 0) return

            // highlight the square they moused over
            if (this.game.turn() === 'b') {
                this.greySquare(square)

                // highlight the possible squares for this piece
                for (var i = 0; i < moves.length; i++) {
                    this.greySquare(moves[i].to)
                }
            }
        },

        onMouseoutSquare: function (square, piece) {
            this.removeGreySquares()
        },

        onSnapEnd: function () {
            this.board.position(this.game.fen())
        },

        updateStatus: function () {
            var status = ''

            var moveColor = 'White'
            if (this.game.turn() === 'b') {
                moveColor = 'Black'
            }

            // checkmate?
            if (this.game.in_checkmate()) {
                status = 'Game over, ' + moveColor + ' is in checkmate.'
                console.log(
                    'Checkmate!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!',
                    moveColor
                )
                let stats = {
                    user_white: 1,
                    user_black: 1,
                    white_final_score: 1,
                    black_final_score: 1,
                    winner: 1,
                    moves: 1,
                    gamemode: 1,
                    private: 0,
                }

                if (moveColor == 'White') {
                    stats.winner = 1
                } else if (moveColor == 'Black') {
                    stats.winner = 0
                }

                this.$emit('gameFinished', stats)
            }

            // draw?
            else if (this.game.in_draw()) {
                status = 'Game over, drawn position'
                let stats = {
                    user_white: 1,
                    user_black: 1,
                    white_final_score: 1,
                    black_final_score: 1,
                    winner: 2,
                    moves: 1,
                    gamemode: 1,
                    private: 0,
                }

                this.$emit('gameFinished', stats)
            }

            // game still on
            else {
                status = moveColor + ' to move'

                // check?
                if (this.game.in_check()) {
                    status += ', ' + moveColor + ' is in check'
                }
            }

            //this.$status.html(status)
            //this.$fen.html(this.game.fen())
            //this.$pgn.html(this.game.pgn())
        },
    },

    destroyed() {
        console.log('chessboard destroyed')
    },

    mounted() {
        console.log('antes de set board ')
        this.board = ChessBoard('board', this.config)
        this.updateStatus()
        console.log('despues de set board')
    },

    computed: {
        user: function () {
            return this.$store.state.user
        },
    },
}
</script>

<style lang="scss" scoped>
#board {
    width: 700px;
    height: 700px;
    max-width: 800px;
    max-width: 800px;
}
#status,
#png,
#fen {
    color: white;
}

@media (max-height: 615px) {
    #board {
        width: 400px !important;
        height: 400px !important;
    }
}

@media (max-width: 700px) {
    #board {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 550px;
    }
}

@media (max-width: 600px) {
    #board {
        height: 550px !important;
    }
}

@media (max-width: 500px) {
    #board {
        height: 450px !important;
    }
}

@media (max-width: 400px) {
    #board {
        height: 300px !important;
    }
}
</style>