<template>
    <div>
        <div class="mx-auto overflow-hidden" v-if="mobile">
            <v-app-bar class="appbar" app src="../assets/cc.jpg">
                <v-app-bar-nav-icon
                    @click.stop="drawer = !drawer"
                    color="black"
                ></v-app-bar-nav-icon>

                <v-toolbar-title class="toolbar-title" v-if="dataReady"
                    >Welcome to PChess, {{ this.user.name }}</v-toolbar-title
                >
            </v-app-bar>

            <v-navigation-drawer
                v-model="drawer"
                absolute
                temporary
                width="70px"
            >
                <v-list nav dense>
                    <v-list-item-group
                        active-class="deep-purple--text text--accent-4"
                    >
                        <v-list-item>
                            <v-btn icon @click="goToHome()">
                                <v-icon color="grey darken-4">fa-home</v-icon>
                            </v-btn>
                        </v-list-item>

                        <v-list-item>
                            <v-btn icon @click="goToProfile()">
                                <v-icon color="light-green darken-2"
                                    >fa-user-circle</v-icon
                                >
                            </v-btn>
                        </v-list-item>

                        <v-list-item>
                            <v-btn icon @click="goToMessages()">
                                <v-icon color="brown darken-3"
                                    >fa-envelope</v-icon
                                >
                            </v-btn>
                        </v-list-item>

                        <v-list-item>
                            <v-btn icon @click="goToPlay()">
                                <v-icon color="yellow accent-1"
                                    >fa-chess</v-icon
                                >
                            </v-btn>
                        </v-list-item>

                        <v-list-item>
                            <v-btn icon @click="goToLearn()">
                                <v-icon color="blue darken-1"
                                    >fa-book-open</v-icon
                                >
                            </v-btn>
                        </v-list-item>

                        <v-list-item>
                            <v-btn icon @click="goToWatch()">
                                <v-icon color="purple lighten-1">fa-eye</v-icon>
                            </v-btn>
                        </v-list-item>

                        <v-list-item>
                            <v-btn icon @click="goToFriends()">
                                <v-icon color="yellow darken-2"
                                    >mdi-account-group</v-icon
                                >
                            </v-btn>
                        </v-list-item>

                        <v-list-item>
                            <v-btn icon @click="logout()">
                                <v-icon color="white"
                                    >mdi-logout-variant</v-icon
                                >
                            </v-btn>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>

            <v-main class="main">
                <router-view :key="$route.fullPath"></router-view>
            </v-main>
        </div>

        <v-app-bar class="appbar" app src="../assets/cc.jpg" v-if="!mobile">
            <v-app-bar-nav-icon @click="goToHome()">
                <v-icon color="grey darken-4">fa-home</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title class="toolbar-title" v-if="dataIsReady"
                >Welcome to PChess, {{ this.user.name }}</v-toolbar-title
            >

            <v-spacer></v-spacer>

            <v-btn icon @click="goToProfile()">
                <v-icon color="light-green darken-2">fa-user-circle</v-icon>
            </v-btn>

            <v-btn icon @click="goToMessages()">
                <v-icon color="brown darken-3">fa-envelope</v-icon>
            </v-btn>

            <v-btn icon @click="goToPlay()">
                <v-icon color="yellow accent-1">fa-chess</v-icon>
            </v-btn>

            <v-btn icon @click="goToLearn()">
                <v-icon color="blue darken-1">fa-book-open</v-icon>
            </v-btn>

            <v-btn icon @click="goToWatch()">
                <v-icon color="purple lighten-1">fa-eye</v-icon>
            </v-btn>

            <v-btn icon @click="goToFriends()">
                <v-icon color="yellow darken-2">mdi-account-group</v-icon>
            </v-btn>

            <v-btn icon @click="logout()">
                <v-icon color="white">mdi-logout-variant</v-icon>
            </v-btn>
        </v-app-bar>

        <v-main class="main" v-if="!mobile">
            <router-view :key="$route.fullPath"></router-view>
        </v-main>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: null,
            dataReady: false,
            dataIsReady: false,
            drawer: false,
            mobile: false,
            windowWidth: window.innerWidth,
            items: [
                {
                    title: 'Profile',
                    icon: 'fa-user-circle',
                    color: 'light-green darken-2',
                    to: '/profile',
                },
                {
                    title: 'Play',
                    icon: 'fa-user-circle',
                    color: 'light-green darken-2',
                    to: '/play',
                },
                {
                    title: 'Home',
                    icon: 'fa-user-circle',
                    color: 'light-green darken-2',
                    to: '/home',
                },
                {
                    title: 'Start',
                    icon: 'fa-user-circle',
                    color: 'light-green darken-2',
                    to: '/start',
                },
            ],
            mini: true,
        }
    },

    sockets: {
        wasPlaying: function (data) {
            console.log(
                'This user was playing in room',
                data.roomId,
                'with game id',
                data.gameId
            )
        },
    },

    mounted() {
        this.user = this.$store.state.user
        this.dataIsReady = true
        this.registerConnection()
        console.log('user in routting', this.user)
        this.checkWidowWidth()
    },

    computed: {},

    destroyed: function () {
        console.log('destroy')
    },

    methods: {
        registerConnection() {
            this.$socket.emit('registerConnection', this.user)
        },

        disconnectUser() {
            this.$socket.emit('disconnectUser', this.user)
        },

        checkWidowWidth: function () {
            if (this.windowWidth <= 550) {
                this.mobile = true
                console.log('mobile', this.mobile)
            }
        },

        goToHome: function () {
            this.$router.push('/home')
        },

        goToPlay: function () {
            this.$router.push('/play')
        },

        goToLearn: function () {
            this.$router.push('/learn')
        },

        goToWatch: function () {
            this.$router.push('/watch')
        },

        goToFriends: function () {
            this.$router.push('/friends')
        },

        goToProfile: function () {
            const name = this.$store.state.user.name
            this.$router.push({ path: `/` })
            this.$router.push({ path: `profile/${name}`, params: { name } })
        },

        goToMessages: function () {
            this.$router.push('/messages')
        },

        logout() {
            this.$store.commit('deleteUser')
            this.$store.commit('setUserIsNotAuthenticated')
            this.$router.push('/start')
            this.disconnectUser()
            console.log('user in state', this.$store.state.user)
        },
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
.main {
    font-family: 'Lato', cursive;
    height: 100% !important;
    width: 100%;
}

.toolbar-title {
    color: black;
    font-family: 'Staatliches', cursive;
}

.button {
    outline: none;
    position: relative;
    z-index: 1;
}

.button:hover {
    cursor: pointer;
}

.button:before {
    transition: 0.5s all ease !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    right: 50% !important;
    bottom: 0 !important;
    opacity: 0 !important;
    content: '' !important;
    background-color: rgb(89, 255, 255) !important;
}

.button:hover:before {
    transition: 0.5s all ease !important;
    left: 0 !important;
    right: 0 !important;
    opacity: 1 !important;
    z-index: -1 !important;
}
</style>