<template>
    <div class="centerdiv">
        <!-- Game recommendations-->
        <div class="leftcard">
            <v-card
                class="mx-auto my-12 leftcard-content"
                width="90%"
                height="93%"
            >
                <div class="real-time-games">
                    <div class="live-games-title">Watch real time games</div>

                    <div class="live-games">
                        <v-container class="">
                            <v-row no-gutters style="margin-left: 15px">
                                <v-col
                                    v-for="(room, i) in filteredRooms"
                                    :key="i"
                                    cols="12"
                                    sm="6"
                                    md="4"
                                    xl="3"
                                >
                                    <v-card
                                        class="singleCard"
                                        @click="openGame(room)"
                                        style="width: auto"
                                    >
                                        <img
                                            src="../assets/chessboard.png"
                                            alt=""
                                            style="
                                                width: 100%;
                                                max-height: 300px;
                                            "
                                        />
                                        <div class="cardData">
                                            <div>
                                                {{ room.room.user1.name }} vs
                                                {{ room.room.user2.name }}
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </div>
            </v-card>
        </div>

        <!-- Search User to-->
        <div class="rightcard">
            <v-card
                class="mx-auto my-12 rightcard-content"
                id="validatorcard"
                width="90%"
                height="93%"
            >
                <div class="search-user-game">
                    <div class="search">
                        <div class="search-container">
                            <v-text-field
                                style="width: 80%"
                                v-model="searchPlayer"
                                placeholder="Search User playing"
                                outlined
                            ></v-text-field>
                        </div>
                    </div>
                </div>

                <div class="recommendation-games">
                    <div>
                        We recommend you to play/watch this players based on how
                        you play
                    </div>

                    <v-list three-line class="v-list">
                        <div
                            v-for="(user, i) in playerRecommendations"
                            :key="i"
                        >
                            <v-subheader
                                v-if="user.header"
                                v-text="user.header"
                            ></v-subheader>

                            <v-divider
                                v-else-if="user.divider"
                                :inset="user.inset"
                            ></v-divider>

                            <v-list-item v-else>
                                <v-list-item-avatar>
                                    <v-img :src="user.image"></v-img>
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <div class="flex-row">
                                        <v-list-item-title
                                            v-html="user.name"
                                        ></v-list-item-title>
                                        <div class="flex-row">
                                            <v-card elevation="5">
                                                <v-btn
                                                    icon
                                                    @click="
                                                        goToProfile(user.name)
                                                    "
                                                >
                                                    <v-icon
                                                        color="light-green darken-2"
                                                        >fa-user-circle</v-icon
                                                    >
                                                </v-btn>
                                            </v-card>
                                            <div>
                                                <v-btn
                                                    style="
                                                        margin-left: 5px;
                                                        width: 36px !important;
                                                        height: 36px;
                                                    "
                                                    small
                                                    @click="
                                                        sendFriendRequest(user)
                                                    "
                                                >
                                                    <v-icon
                                                        medium
                                                        color="green"
                                                    >
                                                        mdi-account-check
                                                    </v-icon>
                                                </v-btn>
                                            </div>
                                        </div>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </div>
                    </v-list>
                </div>
            </v-card>
        </div>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="friendDialog" width="500">
                    <v-card>
                        <v-card-title class="headline green lighten-2">
                            Friend Request Sended
                        </v-card-title>

                        <v-divider></v-divider>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                text
                                @click="friendDialog = false"
                            >
                                Ok
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            user: {},
            searchPlayer: '',
            playerRecommendations: [],
            rooms: [],
            mostUsedOpeningMove: '',
            games: [],
            moves: [],
            firstMoveList: [],
            friendDialog: false,
        }
    },

    sockets: {
        getRooms(rooms) {
            this.rooms = rooms
            for (let i = 0; i < this.rooms.length; i++) {
                if (rooms[i].room.private === 1) {
                    this.rooms.splice(i, 1)
                }
            }
            console.log('current rooms', this.rooms)
        },
    },

    methods: {
        openGame(room) {
            console.log('room', room)
            let gameId = room.room.user1.gameId
            let roomId = room.roomId
            this.$router.push({
                path: `WatchGameOnline/${gameId}/${roomId}`,
                params: { gameId, roomId },
            })
        },

        goToProfile(name) {
            name = name.substring(0, name.length - 3)
            console.log('name to go: ', name)
            this.$router.push({ path: `profile/${name}`, params: { name } })
        },

        sendFriendRequest(item) {
            console.log('item: ', item)
            if (item.user_black) {
                this.$api
                    .post(`/friends/${item.user_black}`, null, {
                        headers: {
                            Authorization: `Bearer ${this.user.token}`,
                        },
                    })
                    .then((response) => {
                        if ((response.status = 200)) {
                            this.friendDialog = true
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            } else {
                this.$api
                    .post(`/friends/${item.user_white}`, null, {
                        headers: {
                            Authorization: `Bearer ${this.user.token}`,
                        },
                    })
                    .then((response) => {
                        if ((response.status = 200)) {
                            this.friendDialog = true
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },

        getRooms() {
            this.$socket.emit('getRooms')
        },

        getMostUsedOpeningsByUser() {
            this.$api
                .get(`/users/${this.user.id}/games`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.games = response.data
                    console.log('games', this.games)
                    this.getGameMoves(this.games)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        async getGameMoves(games) {
            const calls = []

            for (let i = 0; i < games.length; i++) {
                calls.push(`/games/${games[i].id}/moves`)
            }

            const responses = await Promise.all(
                calls.map((c) =>
                    this.$api.get(c, {
                        headers: {
                            Authorization: `Bearer ${this.user.token}`,
                        },
                    })
                )
            )

            const moves = []

            /*
            const data = await Promise.all(
               
                responses.map((r) => {
                    console.log('r: ', r)
                    if (r.data.length > 0) {
                        return r.data[0].name
                    }
                })
                
            )
            */

            responses.forEach((r) => {
                console.log('r: ', r)
                if (r.data.length > 0) {
                    moves.push(r.data[0].name)
                }
            })

            // this.mostUsedOpeningMove = this.getHighestOcurrency(this.moves)
            //console.log('most user opening move: ', this.mostUsedOpeningMove)
        },

        getHighestOcurrency(array) {
            console.log('array: ', array)
            if (array.length == 0) return null
            var modeMap = {}
            var maxEl = array[0],
                maxCount = 1
            for (var i = 0; i < array.length; i++) {
                var el = array[i]
                if (modeMap[el] == null) modeMap[el] = 1
                else modeMap[el]++
                if (modeMap[el] > maxCount) {
                    maxEl = el
                    maxCount = modeMap[el]
                }
            }
            return maxEl
        },

        getUsersTrends() {
            this.$api
                .get(`/users/trends`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.playerRecommendations = response.data
                    console.log(
                        'playuer recomendations: ',
                        this.playerRecommendations
                    )
                    this.treatUsers(this.playerRecommendations)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        treatUsers(users) {
            let imagepath =
                'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
            for (let i = 0; i < users.length; i++) {
                users[i].image = imagepath + users[i].image
                if (users[i].user_black) {
                    users[i].name = users[i].name + '(B)'
                } else if (users[i].user_white) {
                    users[i].name = users[i].name + '(W)'
                }
            }
        },
    },

    mounted() {
        this.user = this.$store.state.user
        this.getRooms()
        //this.getMostUsedOpeningsByUser()
        this.getUsersTrends()
    },

    computed: {
        filteredRooms: function () {
            return this.rooms.filter((r) => {
                return (
                    r.room.user1.name
                        .toLowerCase()
                        .includes(this.searchPlayer.toLowerCase()) ||
                    r.room.user2.name
                        .toLowerCase()
                        .includes(this.searchPlayer.toLowerCase())
                )
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.centerdiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 90vh !important;
    margin: 0 auto;

    .leftcard {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;

        .real-time-games {
            display: flex;
            flex-direction: column;
            height: 100%;

            .singleCard {
                margin: 10px;
            }

            .live-games {
                overflow-y: scroll;
                height: 100%;
            }

            .live-games-title {
                margin-top: 20px;
                font-size: 15;
            }
        }
    }

    .rightcard {
        margin-left: 50px;
        height: 100%;
        width: 50%;

        .rightcard-content {
            display: flex;
            flex-direction: column;
            align-items: center;

            .search-user-game {
                width: 70%;

                .search {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    align-items: center;

                    .search-container {
                        width: 100%;
                        text-align: center;
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            .recommendation-games {
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                width: 400px;
                height: 70%;

                .v-list {
                    height: 55vh; /* or any height you want */
                    overflow-y: auto;

                    .flex-row {
                        display: flex;
                        flex-direction: row !important;
                    }

                    .v-list-item__content {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
}

@media (max-height: 615px) {
    .centerdiv {
        overflow-y: scroll;
        height: 800px !important;
    }

    .rightcard {
        height: 650px !important;
    }

    .leftcard {
        height: 650px !important;
    }
}

@media (max-width: 1100px) {
    .centerdiv {
        display: flex;
        flex-direction: column;
        height: 100% !important;
    }

    .leftcard,
    .rightcard {
        width: 90% !important;
        height: 650px !important;
    }

    .rightcard {
        margin-left: 0px !important;
    }

    .leftcard-content {
        height: 95% !important;
    }

    .rightcard-content {
        height: 500px !important;
    }

    .search-user-game {
        width: 90%;
    }

    .recommendation-games {
        width: 80% !important;

        .v-list {
            height: 300px !important; /* or any height you want */
        }
    }
}

@media (max-width: 700px) {
    .leftcard,
    .rightcard {
        width: 95% !important;
        height: auto !important;
    }

    .rightcard {
        margin-left: 0px !important;
    }
}
</style>