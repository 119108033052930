<template>
    <div class="gamesplayed">
        <v-data-table :headers="headers" :items="games" :items-per-page="10">
            <template v-slot:[`item.players`]="{ item }">
                <div class="playername" @click="goToProfile(item.user_white)">
                    {{ item.user_white }}
                </div>
                <div class="playername" @click="goToProfile(item.user_black)">
                    {{ item.user_black }}
                </div>
            </template>

            <template v-slot:[`item.elo`]="{ item }">
                <div>{{ item.white_final_score }}</div>
                <div>{{ item.black_final_score }}</div>
            </template>

            <template v-slot:[`item.winner`]="{ item }">
                <v-icon :color="getColorFromResult(item)" medium>
                    {{ item.winner }}
                </v-icon>
            </template>

            <template v-slot:[`item.gamemode`]="{ item }">
                <v-icon :color="getColorFromGamemode(item)" medium>
                    {{ item.gamemode }}
                </v-icon>
            </template>

            <template v-slot:[`item.date`]="{ item }">
                <div style="display: flex">
                    {{ item.date }}
                    <v-icon
                        v-if="item.private === 0"
                        class="playername"
                        medium
                        @click="openGame(item)"
                    >
                        mdi-magnify
                    </v-icon>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: ['name'],
    name: 'GamesPlayed',
    data() {
        return {
            user: null,
            iAmWhite: null,
            headers: [
                {
                    text: 'Players',
                    align: 'center',
                    sortable: false,
                    value: 'players',
                },
                { text: 'ELO', value: 'elo', sortable: false, align: 'center' },
                {
                    text: 'Mode',
                    value: 'gamemode',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'Result',
                    value: 'winner',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'Moves',
                    value: 'moves',
                    sortable: false,
                    align: 'center',
                },
                { text: 'Date', value: 'date', align: 'center' },
            ],
            games: [],
            token: null,
            userUpdated: null,
        }
    },

    methods: {
        openGame(game) {
            console.log('open game', game)
            this.$router.push({ path: `/` })
            this.$router.push({
                path: `watchGame/${game.id}`,
            })
        },

        treatGames() {
            console.log('treat, user.name', this.user.name)
            this.games.forEach((game) => {
                if (this.userUpdated.name == game.user_white) {
                    switch (game.winner) {
                        case 0:
                            game.winner = 'mdi-plus-box'
                            break
                        case 1:
                            game.winner = 'mdi-minus-box'
                            break
                        case 2:
                            game.winner = 'mdi-equal-box'
                            break
                    }
                } else if (this.userUpdated.name == game.user_black) {
                    switch (game.winner) {
                        case 0:
                            game.winner = 'mdi-minus-box'
                            break
                        case 1:
                            game.winner = 'mdi-plus-box'
                            break
                        case 2:
                            game.winner = 'mdi-equal-box'
                            break
                    }
                }

                switch (game.gamemode) {
                    case 1:
                        game.gamemode = 'mdi-lightning-bolt'
                        break
                    case 2:
                        game.gamemode = 'mdi-speedometer'
                        break
                    case 3:
                        game.gamemode = 'mdi-timer-sand'
                        break
                }

                //game.date = this.parseDateToFrontEndDate(game.date)
                game.date = this.$moment(game.date).fromNow()
            })
        },

        getColorFromResult(item) {
            switch (item.winner) {
                case 'mdi-plus-box':
                    return 'green darken-2'
                case 'mdi-minus-box':
                    return 'red darken-4'
                case 'mdi-equal-box':
                    return 'blue-grey darken-3'
            }
        },

        getColorFromGamemode(item) {
            switch (item.gamemode) {
                case 'mdi-lightning-bolt':
                    return 'yellow lighten-1'
                case 'mdi-speedometer':
                    return 'light-green accent-3'
                case 'mdi-timer-sand':
                    return 'brown darken-1'
            }
        },

        getUserGames() {
            this.$api
                .get(`/users/${this.user.id}/games`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    this.games = response.data
                    console.log('this.games', this.games)
                    this.treatGames()
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getUserInfoFromName() {
            console.log('this.name from prop', this.$props.name)
            this.$api
                .get(`/users/search/${this.$props.name}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    console.log('res', response.data)
                    this.user = response.data
                    this.userUpdated = response.data
                    console.log('USER', this.user)
                    this.getUserGames()
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        goToProfile(name) {
            this.$router.push({ path: `/` })
            this.$router.push({ path: `profile/${name}`, params: { name } })
            //this.$router.go();
        },
    },

    mounted() {
        console.log('this.name', this.$props.name)
        this.token = this.$store.state.user.token
        //this.user = this.$store.state.user;
        this.getUserInfoFromName()
    },
}
</script>

<style lang="scss" scoped>
.gamesplayed {
    display: flex;
    flex-direction: column;

    .playername {
        font-weight: bold;
        color: rgb(161, 161, 160);
    }

    .v-data-table::v-deep th {
        font-size: 16px !important;
    }

    .v-data-table::v-deep th span {
        color: white;
    }

    .v-data-table::v-deep td {
        font-size: 16px !important;
        justify-content: center !important;
    }

    .v-data-table::v-deep tr {
        height: 60px !important;
    }
}

@media (max-width: 720px) {
    .gamesplayed .v-data-table::v-deep td {
        justify-content: space-between !important;
    }
}
</style>