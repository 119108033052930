var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gamesplayed"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.games,"items-per-page":10},scopedSlots:_vm._u([{key:"item.players",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"playername",on:{"click":function($event){return _vm.goToProfile(item.user_white)}}},[_vm._v(" "+_vm._s(item.user_white)+" ")]),_c('div',{staticClass:"playername",on:{"click":function($event){return _vm.goToProfile(item.user_black)}}},[_vm._v(" "+_vm._s(item.user_black)+" ")])]}},{key:"item.elo",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.white_final_score))]),_c('div',[_vm._v(_vm._s(item.black_final_score))])]}},{key:"item.winner",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColorFromResult(item),"medium":""}},[_vm._v(" "+_vm._s(item.winner)+" ")])]}},{key:"item.gamemode",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getColorFromGamemode(item),"medium":""}},[_vm._v(" "+_vm._s(item.gamemode)+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex"}},[_vm._v(" "+_vm._s(item.date)+" "),(item.private === 0)?_c('v-icon',{staticClass:"playername",attrs:{"medium":""},on:{"click":function($event){return _vm.openGame(item)}}},[_vm._v(" mdi-magnify ")]):_vm._e()],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }