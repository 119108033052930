<template>
    <div>
        <OpeningsDefenses/>
    </div>
</template>

<script>
import OpeningsDefenses from '../components/OpeningsDefenses.vue'
    export default {
        name: 'OpenignDefenses',
        components: { OpeningsDefenses },
        data () {
            return {

            }
        },

        methods: {

        },

    }
</script>

<style lang="scss" scoped>

</style>