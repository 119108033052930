<template>
    <div class="centerdiv">
        <div class="leftcard">
            <v-card class="mx-auto my-12 chessboard">
                <div id="board" style="width: 700px; height: 700px"></div>
                <div class="academy-moves">
                    <v-btn icon @click="startFEN()">
                        <v-icon color="grey darken-2">mdi-reload</v-icon>
                    </v-btn>

                    <v-btn icon @click="lastMoveFEN()">
                        <v-icon color="grey darken-2">fa-arrow-left</v-icon>
                    </v-btn>

                    <v-btn icon @click="nextMoveFEN()">
                        <v-icon color="grey darken-2">fa-arrow-right</v-icon>
                    </v-btn>
                </div>
            </v-card>
        </div>

        <!-- Description and move list-->
        <div class="rightcard">
            <v-card
                class="mx-auto my-12"
                id="validatorcard"
                width="90%"
                height="93%"
            >
                <div class="opening-info">
                    <div class="opening-info-top">
                        <div class="center-all" id="openingDefense-name">
                            <h2>{{ this.openingDefense.name }}</h2>
                        </div>

                        <div class="center-all" id="openingDefense-description">
                            {{ this.openingDefense.description }}
                        </div>
                    </div>
                    <div class="opening-info-bottom">
                        <div>
                            <div>
                                <v-btn
                                    color="deep-purple"
                                    accent-3
                                    dark
                                    medium
                                    elevation="5"
                                    @click="goToAcademyExercise()"
                                >
                                    Try an exercise
                                    <v-icon>mdi-chess-pawn</v-icon>
                                </v-btn>
                            </div>
                        </div>
                        <div class="overflow-y">
                            <div>Moves List</div>
                            <div class="move-list">
                                <v-col v-for="(move, i) in moveList" :key="i">
                                    <v-card
                                        class="move-list-item"
                                        @click="goToMove(move)"
                                    >
                                        <div v-if="i % 2 == 0">
                                            {{ move.order }} w.
                                        </div>
                                        <div v-if="i % 2 != 0">
                                            {{ move.order }} b.
                                        </div>
                                        <div class="mx-1" style="width: 5px">
                                            -
                                        </div>
                                        <div>{{ move.name }}</div>
                                    </v-card>
                                </v-col>
                            </div>
                        </div>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import ChessBoard from 'chessboardjs-vue'
var chessMoveSound = new Audio(require('../assets/chess_move.mp3'))
export default {
    props: {
        opeiningDefense: { type: Object, default: () => ({}) },
    },
    data() {
        return {
            user: null,
            moveList: [],
            board: null,

            index: -1,
            configWhite: {
                orientation: 'white',
                position: 'start',
            },

            configBlack: {
                orientation: 'black',
                position: 'start',
            },
        }
    },

    methods: {
        goToMove(move) {
            this.board.position(move.fen)
        },

        getOpeningDefenseMoves() {
            this.$api
                .get(`/academy/${this.openingDefense.id}/moves`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.moveList = response.data
                    console.log('reponse', this.moveList)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        goToAcademyExercise() {
            let openingDefense = this.openingDefense
            this.$router.push({
                name: 'AcademyExercise', //use name for router push
                params: { openingDefense },
            })
        },

        startFEN() {
            this.board.position('start')
            this.index = -1
        },

        nextMoveFEN() {
            if (this.index < -1) {
                this.index = -1
            }

            this.index++

            if (this.index > this.moveList.length - 1) {
                this.index--
            } else {
                this.board.position(this.moveList[this.index].fen)
                chessMoveSound.play()
            }
        },

        lastMoveFEN() {
            console.log('this.index', this.index)
            this.index--
            if (this.index > -1) {
                this.board.position(this.moveList[this.index].fen)
                chessMoveSound.play()
            } else {
                if (this.index === -1) {
                    chessMoveSound.play()
                    this.board.position('start')
                } else {
                    this.board.position('start')
                }
            }
        },
    },

    mounted() {
        this.user = this.$store.state.user

        //this.openingDefense = this.$route.params.openingDefense

        console.log('this openiongdenfese', this.openingDefense)
        this.getOpeningDefenseMoves()

        if (this.openingDefense.type == 0) {
            this.board = ChessBoard('board', this.configWhite)
        } else {
            this.board = ChessBoard('board', this.configBlack)
        }
    },

    computed: {
        openingDefense() {
            return this.$store.state.openingDefense
        },
    },
}
</script>

<style lang="scss" scoped>
.centerdiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 90vh !important;
    margin: 0 auto;

    .leftcard {
        height: 100%;
        width: 80%;
        display: flex;
        justify-content: center;

        .chessboard {
            height: 93%;
            width: 93%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
    }

    .rightcard {
        height: 100%;
        width: 50%;

        .opening-info {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;

            .opening-info-top {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                #openingDefense-name {
                    margin-bottom: 10px;
                    margin-top: 20px;
                }

                #openingDefense-description {
                    overflow: auto;
                }
            }

            .opening-info-bottom {
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-top: 40px;

                .overflow-y {
                    overflow-y: auto;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 10px;
                }
            }

            .center-all {
                width: 90%;
                height: auto;
                display: flex;
                justify-content: center;
                text-align: justify;
                align-items: center;
            }
        }

        .move-list {
            margin-top: 20px;
            width: 100%;
            height: auto;
            overflow-y: scroll;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            align-content: space-around;
            text-align: center;

            .move-list-item {
                width: 90px;
                display: flex;
                flex-direction: row;
                height: 30px;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                padding: 5px 0;
            }
        }
    }
}

@media (max-width: 1300px) and (max-height: 625px) {
    .centerdiv {
        height: 750px !important;
    }

    #board {
        width: 400px !important;
        height: 400px !important;
    }

    .leftcard {
        height: 650px !important;
    }

    .rightcard {
        height: 650px !important;
    }
}

@media (max-width: 1100px) {
    .centerdiv {
        display: flex;
        flex-direction: column;
        height: 100% !important;
    }

    .leftcard,
    .rightcard {
        width: 90% !important;
        height: auto !important;
    }

    .rightcard {
        margin-left: 0px !important;
    }

    #validatorcard {
        height: 90% !important;
    }
}

@media (max-width: 950px) {
    #board {
        width: 550px !important;
        height: 550px !important;
    }
}

@media (max-width: 770px) {
    .leftcard,
    .rightcard {
        width: 95% !important;
        height: auto !important;
    }

    .rightcard {
        margin-left: 0px !important;
    }

    .opening-info-bottom {
        display: flex !important;
        flex-direction: column !important;

        .overflow-y {
            width: 100% !important;
        }
    }

    #board {
        width: 490px !important;
        height: 490px !important;
    }
}

@media (max-width: 630px) {
    #board {
        width: 390px !important;
        height: 390px !important;
    }
}

@media (max-width: 500px) {
    #board {
        width: 250px !important;
        height: 250px !important;
    }
}
</style>