<template>
    <div>
        <div class="account">
            <v-card
                class="mx-auto my-12 card"
                id="validatorcard"
                width="650px"
                height="700px"
            >
                <br /><br /><br />
                <h3>Personal information</h3>
                <br />
                <div class="validator">
                    <v-file-input
                        v-model="imageName"
                        label="Profile Image"
                        outlined
                        dense
                        required
                        type="file"
                        prepend-icon=""
                    ></v-file-input>
                    <validation-observer ref="observer" v-slot="{ invalid }">
                        <form @submit.prevent="submit" dark>
                            <validation-provider
                                name="name"
                                rules="required|max:20"
                                v-slot="{ errors }"
                            >
                                <v-text-field
                                    v-model="name"
                                    :counter="20"
                                    :error-messages="errors"
                                    label="Name"
                                    required
                                    outlined
                                    disabled
                                    dense
                                ></v-text-field>
                            </validation-provider>

                            <validation-provider
                                v-slot="{ errors }"
                                name="email"
                                rules="email"
                            >
                                <v-text-field
                                    v-model="email"
                                    :error-messages="errors"
                                    label="E-mail"
                                    outlined
                                    disabled
                                    dense
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider
                                name="description"
                                rules="required|max:150"
                                v-slot="{ errors }"
                            >
                                <v-textarea
                                    v-model="description"
                                    :counter="150"
                                    :error-messages="errors"
                                    label="Description"
                                    outlined
                                    dense
                                ></v-textarea>
                            </validation-provider>
                            <validation-provider name="password">
                                <v-text-field
                                    v-model="password"
                                    label="Password"
                                    outlined
                                    type="password"
                                    dense
                                ></v-text-field>
                            </validation-provider>
                            <validation-provider name="confirmPassword">
                                <v-text-field
                                    v-model="confirmPassword"
                                    label="Confirm Password"
                                    outlined
                                    type="password"
                                    :rules="[passwordConfirmationRule]"
                                    dense
                                ></v-text-field>
                            </validation-provider>

                            <v-btn
                                class="mr-4"
                                type="submit"
                                :disabled="invalid"
                            >
                                Edit
                            </v-btn>
                        </form>
                    </validation-observer>
                </div>
            </v-card>
        </div>

        <div class="dialog" style="display: none">
            <div class="text-center">
                <v-dialog v-model="dialog" width="500">
                    <v-card>
                        <v-card-title class="headline cyan lighten-2">
                            Profile Updated!
                        </v-card-title>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="white"
                                text
                                @click="refreshAccountPage"
                            >
                                Okey
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </div>
    </div>
</template>

<script>
import { required, email, max } from 'vee-validate/dist/rules'
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from 'vee-validate'

setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} can not be empty',
})

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters',
})

extend('email', {
    ...email,
    message: 'Email must be valid',
})

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            user: null,
            image: null,
            imageName: null,
            name: '',
            email: '',
            password: '',
            confirmPassword: '',
            description: '',
            rules: {
                passwordRules: (v) => !!v || 'Password is required',
                confirmPasswordRules: (v) => !!v || 'Password is required',
            },
            dialog: null,
        }
    },

    methods: {
        submit() {
            this.$refs.observer.validate()
            let formData = new FormData()
            console.log(this.imageName)
            formData.append('description', this.description)
            if (!this.password === '') {
                formData.append('password', this.password)
            }

            if (this.imageName != '') {
                formData.append('image', this.imageName)
            }

            console.log('formdata', formData)
            this.updateUserInformation(formData)
        },

        updateUserInformation(formData) {
            this.$api
                .put(`/users/${this.user.id}`, formData, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    console.log('Response', response)
                    if (response.status == 200) {
                        this.dialog = true
                        this.image = response.data.image
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        onFileSelected(event) {
            this.image = event
            console.log(event)
            console.log('image', this.image)
        },
        clear() {
            this.imageName = ''
            this.password = null
            this.confirmPassword = null
            this.description = ''
            this.$refs.observer.reset()
        },

        fillFormWithUserData() {
            let filepath =
                'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'

            this.image = filepath + this.user.image
            this.imageName = this.user.image.name

            console.log('image', this.image)

            this.name = this.user.name
            this.email = this.user.email
            this.description = this.user.description
        },

        refreshAccountPage() {
            localStorage.clear()
            this.$store.commit('deleteUser')

            if (this.imageName == null) {
                this.image = this.user.image
            } else {
                this.image = this.imageName.name
            }

            let user = {
                image: this.image,
                id: this.user.id,
                name: this.user.name,
                description: this.description,
                email: this.user.email,
                token: this.user.token,
            }

            let name = user.name

            this.$store.commit('saveUser', user)
            console.log('new user saved', user)
            this.$router.push({
                path: `profile/${user.name}`,
                params: { name },
            })
        },
    },

    mounted() {
        this.user = this.$store.state.user
        console.log('user', this.user)
        this.fillFormWithUserData(this.user)
    },

    computed: {
        passwordConfirmationRule() {
            return () =>
                this.password === this.confirmPassword || 'Password must match'
        },
    },
}
</script>

<style lang="scss" scoped>
.account {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 70%;
    height: 100%;
    margin: 0 auto;

    .card {
        height: 100%;
    }
}

.validator {
    width: 95%;
    margin: 10px 10px 10px 10px !important;
    height: 400px;
}

.text {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statistics {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
}

.input-text {
    ::v-deep {
        .v-text-field {
            input {
                color: blue;
            }
        }
    }
}
</style>