<template>
    <div>
        <Conversations/>
    </div>
</template>

<script>
import Conversations from '../components/Conversations.vue'
export default {
    components: { Conversations },
    data () {
        return {

        }
    },

    methods: {
        
    },

    mounted () {

    }
}
</script>

<style lang="scss" scoped>

</style>