!<template>
    <div class="funcionalitiesdiv">
        <div class="playlearn">
            <div class="explanations">Let's have fun</div>

            <v-btn
                style="margin-top: 10px"
                class="buttonGreen"
                color="deep-purple"
                accent-3
                dark
                x-large
                elevation="5"
                @click="goToPage('play')"
            >
                PLAY
                <v-icon>mdi-chess-pawn</v-icon>
            </v-btn>

            <div class="explanations">Opening/Defenses</div>

            <v-btn
                style="margin-top: 10px"
                class="button"
                color="orange"
                darken-1
                dark
                x-large
                @click="goToPage('learn')"
            >
                LEARN
                <v-icon>mdi-chess-king</v-icon>
            </v-btn>
        </div>

        <div class="watchchallenge">
            <div class="explanations">Players like you</div>

            <v-btn
                style="margin-top: 10px"
                class="buttonPurple"
                color="cyan"
                darken-1
                dark
                x-large
                @click="goToPage('watch')"
            >
                WATCH
                <v-icon>mdi-chess-knight</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {}
    },

    methods: {
        goToPage(page) {
            this.$router.push('/' + page)
        },

        getRandomColor() {
            let color = Math.floor(Math.random() * 16777215).toString(16)
            return '#' + color
        },
    },

    mounted() {
        this.getRandomColor()
    },
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital@1&display=swap');

.funcionalities {
    display: grid;
    grid-template-rows: 50% 50%;
    justify-content: center;
    text-align: center;
    height: 100%;
    font-family: 'Raleway', sans-serif !important;

    .playlearn {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-content: center;
        align-items: center;
    }

    .watchchallenge {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-content: center;
        align-items: center;
    }

    .explanations {
        width: 157px;
        margin-top: 20px;
    }
}

.button,
.buttonPurple,
.buttonGreen,
.buttonGrey {
    width: 157px;
    outline: none;
    position: relative;
    z-index: 1;
    border-radius: 20px;
}

.button:hover,
.buttonPurple:hover,
.buttonGreen:hover,
.buttonGrey:hover {
    cursor: pointer;
}

.button:hover:before,
.buttonPurple:hover:before,
.buttonGreen:hover:before,
.buttonGrey:hover:before {
    transition: 0.5s ease-in !important;
    left: 0 !important;
    right: 0 !important;
    opacity: 1 !important;
    z-index: -1 !important;
}

.button:before {
    transition: 1s ease-in-out !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    right: 50% !important;
    bottom: 0 !important;
    opacity: 0 !important;
    content: '' !important;
    background-color: rgb(89, 255, 255) !important;
}

.buttonPurple:before {
    transition: 1.5s ease !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    right: 50% !important;
    bottom: 0 !important;
    opacity: 0 !important;
    content: '' !important;
    background-color: rgb(121, 0, 115) !important;
}

.buttonGreen:before {
    transition: 2s all ease !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    right: 50% !important;
    bottom: 0 !important;
    opacity: 0 !important;
    content: '' !important;
    background-color: rgb(45, 194, 0) !important;
}

.buttonGrey:before {
    transition: 0.5s all ease !important;
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    right: 50% !important;
    bottom: 0 !important;
    opacity: 0 !important;
    content: '' !important;
    background-color: rgb(94, 93, 94) !important;
}

@media (max-width: 1000px) {
    .funcionalitiesdiv {
        display: flex;
        flex-direction: row;
    }

    .buttonGrey {
        margin-left: 5px;
    }

    .buttonPurple {
        margin-left: 5px;
    }

    .buttonGreen {
        margin-right: 5px;
    }

    .button {
        margin-right: 5px;
    }
}
</style>