<template>
    <div class="mx-auto my-12 grid">
        <div class="funcionalities">
            <Funcionalities />
        </div>

        <div class="games">
            <GamesPlayed :name="this.$store.state.user.name" />
        </div>

        <div class="statistics">
            <Statistics />
        </div>
    </div>
</template>

<script>
import GamesPlayed from '../components/GamesPlayed.vue'
import Statistics from '../components/Statistics.vue'
import Funcionalities from '../components/Funcionalities.vue'

export default {
    components: {
        GamesPlayed,
        Statistics,
        Funcionalities,
    },

    data() {
        return {
            user: null,
        }
    },

    methods: {},

    mounted() {
        this.user = this.$store.state.user
    },
}
</script>

<style lang="scss" scoped>
.grid {
    display: grid;
    grid-template-columns: 23% 48% 28%;
    max-width: 95%;

    .funcionalities {
        max-width: 90%;
    }

    .games {
        max-width: 90%;
    }

    .statistics {
        max-width: 90%;
    }
}

@media (max-width: 1000px) {
    .grid {
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 90%;
        padding-bottom: 70px;
    }

    .games {
        margin-top: 20px;
    }

    .statistics {
        margin-top: 20px;
    }
}
</style>