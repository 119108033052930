<template>
    <div class="main">
        <div class="centerdiv" v-if="dataIsReady">
            <div class="leftcard">
                <v-card class="mx-auto my-12" width="550px" height="800px">
                    <br />
                    <v-img
                        class="image"
                        width="200px"
                        :src="this.image"
                    ></v-img>

                    <div class="profile-name">
                        <div class="text">{{ this.user.name }}</div>
                        <div v-if="userRegister" class="center">
                            <v-btn icon @click="goToAccount()">
                                <v-icon small color="grey lighten-1"
                                    >fa-wrench</v-icon
                                >
                            </v-btn>
                        </div>
                    </div>

                    <v-divider class="mx-1"></v-divider>

                    <div class="profile-name">
                        <h5 class="text">{{ this.user.description }}</h5>
                    </div>

                    <v-divider class="mx-1"></v-divider>

                    <div>
                        <div class="statistics">
                            <div class="games">
                                <div>
                                    Lightning
                                    <v-icon color="yellow lighten-1"
                                        >mdi-lightning-bolt</v-icon
                                    >
                                </div>
                                <div style="color: rgb(110, 245, 255)">
                                    {{ this.elo[0].score }} ELO
                                </div>
                            </div>

                            <div class="games">
                                <div class="win">
                                    {{ this.lightningGames[0].count }} wins
                                </div>
                                <div class="lose">
                                    {{ this.lightningGames[1].count }} loses
                                </div>
                                <div class="draw">
                                    {{ this.lightningGames[2].count }} draws
                                </div>
                            </div>
                            <div style="margin-left: 10px">
                                {{ this.totalLightningGames }} games
                            </div>
                        </div>

                        <v-divider class="mx-1"></v-divider>

                        <div class="statistics">
                            <div class="games">
                                <div>
                                    Speedy
                                    <v-icon color="light-green accent-3"
                                        >mdi-speedometer</v-icon
                                    >
                                </div>
                                <div style="color: rgb(110, 245, 255)">
                                    {{ this.elo[1].score }} ELO
                                </div>
                            </div>

                            <div class="games" style="margin-left: 10px">
                                <div class="win">
                                    {{ this.speedyGames[0].count }} wins
                                </div>
                                <div class="lose">
                                    {{ this.speedyGames[1].count }} loses
                                </div>
                                <div class="draw">
                                    {{ this.speedyGames[2].count }} draws
                                </div>
                            </div>
                            <div style="margin-left: 10px">
                                {{ this.totalSpeedyGames }} games
                            </div>
                        </div>

                        <v-divider class="mx-1"></v-divider>

                        <div class="statistics">
                            <div class="games">
                                <div>
                                    Generic
                                    <v-icon color="brown darken-1"
                                        >mdi-timer-sand</v-icon
                                    >
                                </div>
                                <div style="color: rgb(110, 245, 255)">
                                    {{ this.elo[2].score }} ELO
                                </div>
                            </div>

                            <div class="games" style="margin-left: 10px">
                                <div class="win">
                                    {{ this.genericGames[0].count }} wins
                                </div>
                                <div class="lose">
                                    {{ this.genericGames[1].count }} loses
                                </div>
                                <div class="draw">
                                    {{ this.genericGames[2].count }} draws
                                </div>
                            </div>
                            <div style="margin-left: 10px">
                                {{ this.totalGenericGames }} games
                            </div>
                        </div>
                    </div>
                </v-card>
            </div>

            <!-- Lista de amistades -->
            <div class="rightcard">
                <v-card
                    class="mx-auto my-12"
                    id="validatorcard"
                    width="550px"
                    height="89%"
                >
                    <h5 style="padding-top: 10px">Games</h5>
                    <GamesPlayed :name="this.$route.params.name" />
                </v-card>
            </div>
        </div>
    </div>
</template>

<script>
import GamesPlayed from '../components/GamesPlayed.vue'
export default {
    components: {
        GamesPlayed,
    },
    data() {
        return {
            user: null,
            image: null,
            dataIsReady: false,
            lightningGames: [],
            speedyGames: [],
            genericGames: [],
            totalLightningGames: null,
            totalSpeedyGames: null,
            totalGenericGames: null,
            userRegister: null,
            elo: [],
        }
    },

    methods: {
        getLightningGames() {
            this.$api
                .get(`users/${this.user.id}/games/lightning`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    this.lightningGames = response.data
                    console.log('this light', this.lightningGames)

                    this.treatDataCollection(this.lightningGames)
                    console.log('this light2', this.lightningGames)
                    this.totalLightningGames =
                        this.lightningGames[0].count +
                        this.lightningGames[1].count +
                        this.lightningGames[2].count
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getSpeedyGames() {
            this.$api
                .get(`users/${this.user.id}/games/speedy`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    this.speedyGames = response.data
                    this.treatDataCollection(this.speedyGames)
                    console.log('this.speed', this.speedyGames)
                    this.totalSpeedyGames =
                        this.speedyGames[0].count +
                        this.speedyGames[1].count +
                        this.speedyGames[2].count
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getGenericGames() {
            console.log('user id', this.user.id)
            console.log('token', this.token)
            this.$api
                .get(`users/${this.user.id}/games/generic`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    this.genericGames = response.data
                    this.treatDataCollection(this.genericGames)
                    console.log('this.generic', this.genericGames)
                    this.totalGenericGames =
                        this.genericGames[0].count +
                        this.genericGames[1].count +
                        this.genericGames[2].count
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        treatDataCollection(stats) {
            if (stats.length == 0) {
                stats[0] = { count: 0 }
                stats[1] = { count: 0 }
                stats[2] = { count: 0 }
            } else if (stats.length == 1) {
                stats[1] = { count: 0 }
                stats[2] = { count: 0 }
            } else if (stats.length == 2) {
                stats[2] = { count: 0 }
            }
        },

        getUserInfoFromName() {
            this.$api
                .get(`/users/search/${this.$route.params.name}`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    console.log('res', response.data)
                    this.user = response.data
                    console.log('USER', this.user)

                    let filepath =
                        'https://res.cloudinary.com/dykuavlkm/image/upload/v1630277449/'
                    this.image = filepath + this.user.image

                    this.getGenericGames()
                    this.getLightningGames()
                    this.getSpeedyGames()
                    this.getUserRegister()
                    this.getUserELO()
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        getUserRegister() {
            if (this.user.name == this.$store.state.user.name) {
                this.userRegister = true
            } else {
                this.userRegister = false
            }
        },

        getUserELO() {
            this.$api
                .get(`/score/${this.user.id}/elo`, {
                    headers: {
                        Authorization: `Bearer ${this.token}`,
                    },
                })
                .then((response) => {
                    this.elo = response.data
                    console.log('this elo', this.elo)
                    this.dataIsReady = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        goToAccount() {
            this.$router.push('/account')
        },
    },

    mounted() {
        console.log('name', this.$route.params.name)
        this.token = this.$store.state.user.token
        this.getUserInfoFromName()
        console.log('user', this.user)
    },
}
</script>

<style lang="scss" scoped>
.main {
    //display: grid;
    //grid-template-columns: 50% 50%;
    height: auto;
    overflow-y: scroll;

    //background-image: url(http://localhost:8080/backgroundprofile.jpg);

    .centerdiv {
        display: flex;
        justify-content: center;
        flex-direction: row;
        text-align: center;
        width: 50%;
        height: 100%;
        margin: 0 auto;

        .profile-name {
            display: flex;
            flex-direction: row;
            justify-content: center;

            .center {
                display: flex;
                align-items: center;
            }
        }

        .image {
            border-radius: 50%;
            height: 200px;
            width: 200px;
            display: block;
            margin-left: auto;
            margin-right: auto;
        }

        .win {
            color: greenyellow;
        }

        .lose {
            color: red;
        }

        .draw {
            color: grey;
        }

        .rightcard {
            margin-left: 50px;
            height: 100%;
        }

        .leftcard {
            display: inline-block;
        }

        .validator {
            width: 95%;
            margin: 10px 10px 10px 10px !important;
            height: 400px;
        }

        .text {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .statistics {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100px;
            flex-direction: row;
        }

        .input-text {
            ::v-deep {
                .v-text-field {
                    input {
                        color: blue;
                    }
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .rightcard {
        width: 450px;
    }

    .leftcard {
        width: 450px;
    }

    v-text-field {
        width: 300px;
    }

    .image {
        height: 100px !important;
        width: 100px !important;
    }
}

@media (max-width: 1050px) {
    .rightcard {
        width: 400px;
    }

    .leftcard {
        width: 400px;
    }

    v-text-field {
        width: 500px;
    }
}

@media (max-width: 890px) {
    .rightcard {
        width: 325px;
    }

    .leftcard {
        width: 325px;
    }
}

@media (max-width: 720px) {
    .centerdiv {
        display: flex;
        flex-direction: column !important;
        width: 90% !important;
    }

    .rightcard {
        width: 100% !important;
        margin-left: 0px !important;
    }

    .leftcard {
        width: 100% !important;
    }
}
</style>