<template>
    <div class="centerdiv">
        <!-- FEN of the exercise-->
        <div class="leftcard">
            <v-card class="mx-auto my-12 chessboard">
                <div id="board" style="width: 700px; height: 700px"></div>
            </v-card>
        </div>

        <!-- Description of the exercise-->
        <div class="rightcard">
            <v-card
                class="mx-auto my-12"
                id="validatorcard"
                width="90%"
                height="93%"
            >
                <div class="opening-info">
                    <div class="opening-info-top">
                        <div class="center-all" id="openingDefense-name">
                            Question {{ n_question }}
                        </div>

                        <div class="center-all" id="openingDefense-description">
                            {{ question }}
                        </div>

                        <div
                            v-if="showAnswer"
                            class="center-all"
                            id="openingDefense-description"
                        >
                            {{ answer }}
                        </div>
                    </div>
                    <div class="opening-info-bottom">
                        <v-btn
                            icon
                            @click="lastQuestion()"
                            :disabled="lastQuestionDisabled"
                        >
                            <v-icon color="grey darken-2">fa-arrow-left</v-icon>
                        </v-btn>

                        <v-btn
                            icon
                            @click="nextQuestion()"
                            :disabled="nextQuestionDisabled"
                        >
                            <v-icon color="grey darken-2"
                                >fa-arrow-right</v-icon
                            >
                        </v-btn>

                        <v-btn icon @click="startPosition()">
                            <v-icon color="grey darken-2">mdi-reload</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-btn
                            @click="goBackToSingleOpeningDefense()"
                            class="goBack"
                        >
                            Go Back
                        </v-btn>
                    </div>
                </div>
            </v-card>
        </div>
    </div>
</template>

<script>
import ChessBoard from 'chessboardjs-vue'
import Chess from 'chess.js'
var wrongMove = new Audio(require('../assets/wrong_move.mp3'))
var correctMove = new Audio(require('../assets/correct_move.mp3'))
export default {
    data() {
        return {
            user: null,
            opeiningDefense: {},
            questionList: [],
            n_question: '',
            question: '',
            question_index: 0,
            nextQuestionDisabled: false,
            lastQuestionDisabled: true,
            board: null,
            game: new Chess(),
            config: {
                orientation: 'white',
                position: 'start',
                draggable: true,
                onDragStart: this.onDragStart,
                onDrop: this.onDrop,
                onSnapEnd: this.onSnapEnd,
                onMoveEnd: this.onMoveEnd,
            },
            answer: 'Not quite the move! Try again!',
            showAnswer: false,
        }
    },

    methods: {
        getOpeningDefenseQuestions() {
            this.$api
                .get(`/academy/${this.openingDefense.id}/questions`, {
                    headers: {
                        Authorization: `Bearer ${this.user.token}`,
                    },
                })
                .then((response) => {
                    this.questionsList = response.data
                    console.log('reponse', this.questionsList)
                    console.log('question 1:', this.questionsList[1])
                    this.n_question =
                        this.questionsList[this.question_index].n_question
                    this.question =
                        this.questionsList[this.question_index].question
                    this.config.position =
                        this.questionsList[this.question_index].question_fen

                    this.game.load(
                        this.questionsList[this.question_index].question_fen
                    )

                    if (this.openingDefense.type == 1) {
                        this.config.orientation = 'black'
                    }
                    this.board = ChessBoard('board', this.config)
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        startPosition() {
            this.config.position =
                this.questionsList[this.question_index].question_fen

            this.game.load(this.questionsList[this.question_index].question_fen)
            this.board = ChessBoard('board', this.config)
            this.showAnswer = false
            this.config.draggable = true
        },

        nextQuestion() {
            this.question_index++
            if (this.question_index >= this.questionsList.length) {
                this.nextQuestionDisabled = true
                this.question_index--
            } else {
                this.lastQuestionDisabled = false
                this.n_question =
                    this.questionsList[this.question_index].n_question
                this.question = this.questionsList[this.question_index].question

                this.config.position =
                    this.questionsList[this.question_index].question_fen

                this.game.load(
                    this.questionsList[this.question_index].question_fen
                )
                this.board = ChessBoard('board', this.config)
                this.showAnswer = false
                this.config.draggable = true
            }
        },

        lastQuestion() {
            this.question_index--
            if (this.question_index == -1) {
                this.lastQuestionDisabled = true
                this.question_index++
            } else {
                this.nextQuestionDisabled = false
                this.n_question =
                    this.questionsList[this.question_index].n_question
                this.question = this.questionsList[this.question_index].question
                this.config.position =
                    this.questionsList[this.question_index].question_fen

                this.game.load(
                    this.questionsList[this.question_index].question_fen
                )
                this.board = ChessBoard('board', this.config)
                this.showAnswer = false
                this.config.draggable = true
            }
        },

        goBackToSingleOpeningDefense() {
            this.$router.push({
                name: 'SingleOpeningDefense', //use name for router push
            })
        },

        onDragStart(source, piece, position, orientation) {
            // do not pick up pieces if the game is over
            if (this.game.game_over()) return false

            // only pick up pieces for the side to move
            if (
                (this.game.turn() === 'w' && piece.search(/^b/) !== -1) ||
                (this.game.turn() === 'b' && piece.search(/^w/) !== -1)
            ) {
                return false
            }
        },

        onDrop(source, target, piece, newPos, oldPos, orientation) {
            // see if the move is legal
            var move = this.game.move({
                from: source,
                to: target,
                promotion: 'q', // NOTE: always promote to a queen for example simplicity
            })

            // illegal move
            if (move === null) return 'snapback'

            console.log(
                'answer',
                this.questionsList[this.question_index].answer_fen
            )
            console.log('game fen', this.game.fen())
            if (
                this.game.fen() ===
                this.questionsList[this.question_index].answer_fen
            ) {
                this.answer = this.questionsList[this.question_index].answer
                this.config.draggable = false
                correctMove.play()
            } else {
                this.answer = 'Not quite the move! Try again!'
                wrongMove.play()
            }

            this.showAnswer = true

            this.updateStatus()
        },

        // update the board position after the piece snap
        // for castling, en passant, pawn promotion
        onSnapEnd() {
            this.board.position(this.game.fen())
        },

        updateStatus() {
            var status = ''

            var moveColor = 'White'
            if (this.game.turn() === 'b') {
                moveColor = 'Black'
            }

            // checkmate?
            if (this.game.in_checkmate()) {
                status = 'Game over, ' + moveColor + ' is in checkmate.'
            }

            // draw?
            else if (this.game.in_draw()) {
                status = 'Game over, drawn position'
            }

            // game still on
            else {
                status = moveColor + ' to move'

                // check?
                if (this.game.in_check()) {
                    status += ', ' + moveColor + ' is in check'
                }
            }
        },
    },

    mounted() {
        this.user = this.$store.state.user
        this.getOpeningDefenseQuestions()
    },

    computed: {
        openingDefense() {
            return this.$store.state.openingDefense
        },
    },
}
</script>

<style lang="scss" scoped>
.centerdiv {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    text-align: center;
    width: 90%;
    height: 90vh !important;
    margin: 0 auto;

    .leftcard {
        height: 100%;
        width: 70%;
        display: flex;
        justify-content: center;

        .chessboard {
            height: 93%;
            width: 90%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .rightcard {
        margin-left: 50px;
        height: 100%;
        width: 30%;

        .opening-info {
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;

            .opening-info-top {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                #openingDefense-name {
                    margin-bottom: 50px;
                    margin-top: 20px;
                }

                #openingDefense-description {
                    margin-bottom: 30px;
                }
            }

            .center-all {
                width: 90%;
                height: 100%;
                display: flex;
                justify-content: center;
                text-align: justify;
                align-items: center;
            }
        }

        .goBack {
            margin-top: 40px;
        }
    }
}

@media (max-width: 1300px) and (max-height: 625px) {
    .centerdiv {
        height: 525px !important;
    }

    #board {
        width: 400px !important;
        height: 400px !important;
    }

    .leftcard {
        height: 500px !important;
    }

    .rightcard {
        height: 500px !important;
    }
}

@media (max-width: 1100px) {
    .centerdiv {
        display: flex;
        flex-direction: column;
        height: 100% !important;
    }

    .leftcard,
    .rightcard {
        width: 90% !important;
        height: auto !important;
    }

    .rightcard {
        margin-left: 0px !important;
    }
}

@media (max-width: 900px) {
    #board {
        width: 500px !important;
        height: 500px !important;
    }
}

@media (max-width: 700px) {
    .leftcard,
    .rightcard {
        width: 95% !important;
        height: auto !important;
    }

    .rightcard {
        margin-left: 0px !important;
    }

    #board {
        width: 490px !important;
        height: 490px !important;
    }
}

@media (max-width: 630px) {
    #board {
        width: 390px !important;
        height: 390px !important;
    }
}

@media (max-width: 500px) {
    #board {
        width: 250px !important;
        height: 250px !important;
    }
}
</style>